import React, { createContext, useReducer } from "react";
import UsersReducer from "../reducers/users.reducer";
import { usersColumns } from "../../statics/table-columns/usersColumns";

const initialState: any = {
  users: [],
  filter: {
    role: "",
    searchText: "",
    status: null,
  },
  columns: usersColumns,
  options: {
    withSort: true,
    sortDirection: "asc",
    sortColumn: "",
  },
  pagination: {
    currentPage: 1,
    items: 15,
    totalPages: 1,
    total: 0,
  },
};

export const UsersContext = createContext(initialState);
export const UsersProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(UsersReducer, initialState);

  function setUsers(users: any[]) {
    dispatch({
      type: "SET_USERS",
      payload: { users },
    });
  }

  function sort(params: any) {
    dispatch({
      type: "SORT_USERS",
      payload: { params },
    });
  }

  function setFilter(filter: any) {
    dispatch({
      type: "SET_FILTER",
      payload: { filter },
    });
  }

  function setPagination(params: any) {
    dispatch({
      type: "SET_PAGINATION",
      payload: { params },
    });
  }

  return (
    <UsersContext.Provider
      value={{
        ...state,
        setUsers,
        setFilter,
        setPagination,
        sort,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
