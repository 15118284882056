import "./contact-support.dialog.scoped.scss";
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { icons } from "../../statics/icons";
import { LocalStorageService } from "../../services/local-storage/local-storage";

export const ContactSupportDialog = (props: any) => {
  const { data: contactData } = props;

  const [t] = useTranslation();
  const handleClose = (value: any) => props.onClose(value);
  const localStorageService = new LocalStorageService();

  const tenantIcon: string = localStorageService.getData("tenantIcon");

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      className="custom-dialog"
      dialogClassName="modal-md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("CONTACT_SUPPORT")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="text-center px-3">
          <img
            src={icons.PAGES.DASHBOARD.SUPPORT}
            alt="support"
            className="m-auto"
          />
        </Box>
        <hr className="my-3" />
        <Box className="px-3">
          <Typography
            variant="body1"
            className="mb-3"
            style={{ color: "var(--secondary-color)", fontWeight: 400 }}
          >
            {t("CONTACT_SUPPORT_MODAL.TITLE")}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "var(--secondary-color)", fontWeight: 400 }}
          >
            {t("CONTACT_SUPPORT_MODAL.DESCRIPTION")}
          </Typography>
        </Box>
        <Box className="contact px-3 mt-4">
          <Box
            className="d-flex align-items-center mb-3"
            style={{ gap: "1rem" }}
          >
            <img src={tenantIcon} alt="contact" className="logo" />
            <Box className="item">
              <Typography
                variant="body1"
                style={{
                  color: "var(--secondary-color)",
                  fontWeight: 400,
                }}
              >
                {t("TECHNICAL_CONTACT")}
              </Typography>
              <a href={`mailto:${contactData.techQuestionsEmail}`}>
                {contactData.techQuestionsEmail}
              </a>
            </Box>
          </Box>
          <Box className="d-flex align-items-center" style={{ gap: "1rem" }}>
            <img src={tenantIcon} alt="contact" className="logo" />
            <Box className="item">
              <Typography
                variant="body1"
                style={{
                  color: "var(--secondary-color)",
                  fontWeight: 400,
                }}
              >
                {t("ORGANIZATIONAL_CONTACT")}
              </Typography>
              <a href={`mailto:${contactData.organisationQuestionEmail}`}>
                {contactData.organisationQuestionEmail}
              </a>
            </Box>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme m-0"
          onClick={handleClose.bind(null, false)}
        >
          {t("CLOSE")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
