import "./profile-password.component.scoped.scss";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  passwordRegex,
  capitalChar,
  digitChar,
  specialChar,
} from "../../../../../statics/validators";
import { UserService } from "../../../../../services/user/user.service";
import { GlobalContext } from "../../../../../store";
import { showPopupToastMsg, showToastMsg } from "../../../../../helpers";

const ProfilePasswordComponent = (props: any) => {
  const [t] = useTranslation();
  const userService = new UserService();

  const { user } = useContext(GlobalContext);

  const [saving, setSaving] = useState(false);
  const password: any = {
    newPassword: "",
    confirmation: "",
  };
  const visibility: any = {
    newPassword: false,
    confirmation: false,
  };
  const [passwordVisibility, setPasswordVisibility] = useState({
    ...visibility,
  });
  const [passwordForm, setPasswordForm] = useState({ ...password });
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const handlePasswordChange = (event: any) => {
    if (event && event.target) {
      const name = event.target.name;
      const value = event.target.value;
      const obj: any = {};
      obj[name] = value;
      setPasswordForm({ ...passwordForm, ...obj });
      setPasswordMatchError(false);
      if (name === "confirmation" && value) {
        setPasswordMatchError(value !== passwordForm.newPassword);
      } else if (name === "newPassword" && value && passwordForm.confirmation) {
        setPasswordMatchError(value !== passwordForm.confirmation);
      }
    }
  };

  const updatePassword = async () => {
    if (saving) return;
    setSaving(true);
    const obj = {
      keycloakUserId: user.keycloakUserId,
      password: passwordForm.newPassword,
    };
    const response = await userService.changePassword(obj);
    setSaving(false);
    if (response && response.message === "success") {
      showToastMsg("success", t("USER_PASSWORD_UPDATED_SUCCESSFULLY"));
    } else {
      showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const togglePasswordVisibility = (type: string) => {
    const obj: any = {};
    obj[type] = !passwordVisibility[type];
    setPasswordVisibility({ ...passwordVisibility, ...obj });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <Box className="profile-password">
      <Box className="content">
        <Box className="row-col-2">
          <Typography variant="subtitle2">{t("NEW_PASSWORD")}</Typography>
          <Box className="standard_text_input">
            <TextField
              className="w-100"
              variant="standard"
              placeholder={t("NEW_PASSWORD")}
              type={passwordVisibility.newPassword ? "text" : "password"}
              value={passwordForm.newPassword}
              autoComplete="off"
              name="newPassword"
              onChange={handlePasswordChange}
              onKeyDown={onKeyDown}
              autoFocus={false}
              inputProps={{
                minLength: 8,
              }}
              InputProps={{
                autoComplete: "one-time-code",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="toggle-password"
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility.bind(
                        null,
                        "newPassword"
                      )}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordVisibility.newPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box className="row-col-2">
          <Typography variant="subtitle2">
            {t("CONFIRM_NEW_PASSWORD")}
          </Typography>
          <Box className="standard_text_input">
            <TextField
              className="w-100"
              variant="standard"
              type={passwordVisibility.confirmation ? "text" : "password"}
              placeholder={t("CONFIRM_NEW_PASSWORD")}
              value={passwordForm.confirmation}
              autoComplete="off"
              name="confirmation"
              onChange={handlePasswordChange}
              onKeyDown={onKeyDown}
              autoFocus={false}
              error={passwordMatchError ? true : false}
              inputProps={{
                minLength: 8,
              }}
              InputProps={{
                autoComplete: "one-time-code",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="toggle-password"
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility.bind(
                        null,
                        "confirmation"
                      )}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordVisibility.confirmation ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box className="row-col-2">
          <Box></Box>
          <Box className="error-msg pt-1">
            {capsLock && (
              <p style={{ color: "#CA310B" }}>{t("caps lock is turned on")}</p>
            )}

            <p
              style={{
                color: passwordForm.newPassword
                  ? passwordForm.newPassword.length < 8 ||
                    passwordForm.newPassword.length > 15
                    ? "#CA310B"
                    : "#a6ba8d"
                  : "",
              }}
            >
              {t("length 8 to 15 characters")}
            </p>
            <p
              style={{
                color: passwordForm.newPassword
                  ? specialChar.test(passwordForm.newPassword)
                    ? "#a6ba8d"
                    : "#CA310B"
                  : "",
              }}
            >
              {t("at least 1 special character")}
            </p>
            <p
              style={{
                color: passwordForm.newPassword
                  ? capitalChar.test(passwordForm.newPassword)
                    ? "#a6ba8d"
                    : "#CA310B"
                  : "",
              }}
            >
              {t("at least 1 capital letter")}
            </p>
            <p
              style={{
                color: passwordForm.newPassword
                  ? digitChar.test(passwordForm.newPassword)
                    ? "#a6ba8d"
                    : "#CA310B"
                  : "",
              }}
            >
              {t("at least 1 digit")}
            </p>
            <p
              style={{
                color: passwordForm.confirmation
                  ? passwordMatchError
                    ? "#CA310B"
                    : "#a6ba8d"
                  : "",
              }}
            >
              {t("passwords do not match")}
            </p>
          </Box>
        </Box>

        <Box className="text-right">
          <Button
            variant="contained"
            color="primary"
            className="btn-primary-theme"
            onClick={updatePassword}
            disabled={
              saving ||
              passwordMatchError ||
              !(passwordForm.newPassword && passwordForm.confirmation) ||
              !passwordRegex.test(passwordForm.newPassword)
            }
          >
            <CheckIcon />
            {t("RESET_PASSWORD")}
            {/* {saving && <CircularProgress color="inherit" size={12} />} */}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePasswordComponent;
