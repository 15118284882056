import "./dashboard.layout.scoped.scss";
import React, { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../store";
import { AuthService } from "../../services/auth/auth.service";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import WorksapcesNavbar from "../../components/workspaces-navbar/workspaces-navabr.component";
import { UserService } from "../../services/user/user.service";
import { MapperService } from "../../services/mapper/mapper.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import io from "socket.io-client";
import { config } from "../../config";
import { SideMenuContentComponent } from "../../components/side-menu/side-menu-content.component";
import { SettingsService } from "../../services/settings/settings.service";

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default function DashboardLayout(props: any) {
  const localStorageService: LocalStorageService = new LocalStorageService();
  const authService: AuthService = new AuthService();
  const settingsService: SettingsService = new SettingsService();
  const notificationsService: NotificationsService = new NotificationsService();
  const userService: UserService = new UserService();
  const mapperService: MapperService = new MapperService();
  const caseContentRef: any = useRef(null);

  const {
    setUser,
    setUserTenants,
    userTenants,
    setHorizontalIcons,
    setNotifications,
    setUnseenNotifications,
    user,
    setEncodeStatus,
    setContactData,
  } = useContext(GlobalContext);

  const setUserData = async () => {
    let userData = await authService.account();
    localStorageService.setData("user", userData);
    setUser(userData);
  };

  async function fetchWorkspacesData() {
    const tenants = await userService.getUserTenants();
    const mappedTenants = mapperService.mapTenants(tenants);
    setUserTenants(mappedTenants);
    setFavIcon(tenants);
    handleEncodeStatus(tenants);
  }

  const handleEncodeStatus = (tenants: any) => {
    const tenantId: string = localStorageService.getData("tenantId");
    const selectedTenant = tenants.find((el: any) => el.id === tenantId);
    setEncodeStatus(selectedTenant.encodeStatus);
  };

  const setFavIcon = (tenants: any) => {
    const tenantId: string = localStorageService.getData("tenantId");
    const selectedTenant = tenants.find((el: any) => el.id === tenantId);
    localStorageService.setData("tenantIcon", selectedTenant.logoUrl);
    handleFavIco();
  };

  const handleFavIco = () => {
    const favicon: any = getFaviconEl();
    const tenantIcon: string = localStorageService.getData("tenantIcon");

    if (tenantIcon) {
      favicon.href = tenantIcon;
    } else {
      favicon.href = "/icons/logos/pAIper-logo.svg";
    }
  };

  const onScroll = async () => {
    if (caseContentRef.current) {
      const { scrollTop } = caseContentRef.current;
      if (scrollTop >= 320) {
        setHorizontalIcons(true);
      } else {
        setHorizontalIcons(false);
      }
    }
  };

  const getNotifications = async () => {
    const notifications = await notificationsService.getNotifications(1, 15);
    const mappedNotifications = mapperService.mapNotifications(notifications);
    setNotifications(mappedNotifications);
    setTimeout(() => {
      setUnseenNotifications(
        notifications.data.filter((item: any) => item.isSeen === 0)
      );
    }, 100);
  };

  const initSocket = () => {
    const socketIO = io("https://socket.api.gethuddly.com/", {
      transports: ["websocket", "xhr-polling"],
    });
    socketIO.emit(
      "join",
      `${config.company}-${
        config.environment
      }-tenant-${localStorageService.getData("tenantId")}`
    );
    // socketIO.on("notification-update", (data: any) => {
    //   if (data.type === 'AI') {
    //     if (data.message === 'File Encoded Successfully') {
    //       setEncodeStatus(1);
    //       console.log('File encoded Successfully');
    //       showToastMsg("success", t("New encoding file was created"));
    //     } else if (data.message === 'File Encode Failed') {
    //       setEncodeStatus(0);
    //       console.log('File Encode Failed');
    //       showToastMsg("error", t("New encoding file not created"));
    //     } else if (data.message === 'AI activated') {
    //       console.log('AI activated', data);
    //     }
    //   } else if (data.usersIds && data.usersIds.includes(user.id) && user.type === "user") {
    //     getNotifications();
    //   }
    // });
    socketIO.on("connect", () => console.log("Socket Connected"));
  };

  const getContactData = async () => {
    const res = await settingsService.getContactInfo();
    setContactData(res);
  };

  useEffect(() => {
    setUserData();
    fetchWorkspacesData();
    handleFavIco();
    getContactData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (user && user.type === "user") {
    initSocket();
    // }
    if (user && user.type === "user") {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (userTenants.length) {
      setFavIcon(userTenants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTenants]);

  return (
    <>
      <div className="dashboard-layout">
        {userTenants.length > 1 && <WorksapcesNavbar />}
        <SideMenuContentComponent />
        <div
          className="content"
          onScroll={() => onScroll()}
          ref={caseContentRef}
        >
          {props.children}
        </div>
      </div>
    </>
  );
}
