import React, { useEffect, useState } from "react";

const RefreshCounterComponent = ({ targetNumber }: any) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (targetNumber !== undefined && targetNumber > 0) {
      let current = 0;
      const stepTime = Math.max(1000 / targetNumber, 10);
      const interval = setInterval(() => {
        current += 1;
        setCount(current);
        if (current >= targetNumber) clearInterval(interval);
      }, stepTime);
      return () => clearInterval(interval);
    }
  }, [targetNumber]);

  return <>{count}</>;
};
export default RefreshCounterComponent;
