import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../store";
import "./profile-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { history } from "react-router-guard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import {
  isAllTextSpaces,
  showPopupToastMsg,
  showToastMsg,
} from "../../../../../helpers";
import { UserService } from "../../../../../services/user/user.service";
import { UserProfileForm } from "../../../../../types/user-profile";
import {
  digitOnly,
  emailRegex,
  nameRegex,
  telephoneRegex,
} from "../../../../../statics/validators";
import { User } from "../../../../../types/user";
import { LocalStorageService } from "../../../../../services/local-storage/local-storage";
import { userTitlesValue } from "../../../../../statics/user-titles";
import SettingSectionTitleComponent from "../../../shared/setting-section-title/setting-section-title.component";
import ProfilePictureComponent from "../profile-picture/profile-picture.component";
import SupportBlockComponent from "../../../../../components/support/support.component";
import ProfilePasswordComponent from "../profile-password/profile-password.component";
import { ConfirmDialog } from "../../../../../dialogs/confirm-dialog/confirm-dialog";

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default function ProfileContent(props: any) {
  const [t] = useTranslation();
  const userService: UserService = new UserService();
  const localStorageService: LocalStorageService = new LocalStorageService();

  const { user, setUser } = useContext(GlobalContext);

  const [profileForm, setProfileForm] = useState(new UserProfileForm());
  const [fromChanged, setFormChanged] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [companyRegisterNumberError, setCompanyRegisterNumberError] = useState(
    false
  );
  const [telephoneError, setTelephoneError] = useState(false);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const submit = () => {
    setSaving(true);
    const payload: any = new UserProfileForm();
    const profileFormCopy: any = { ...profileForm };
    for (const key of Object.keys(payload)) {
      payload[key] = profileFormCopy[key] ? profileFormCopy[key] : null;
    }
    delete payload.profilePicURL;
    updateProfile(payload);
  };

  async function updateProfile(payload: any) {
    const res = await userService.updateUserInfo(payload);
    if (res && res.msg === "Updated") {
      setUser({ ...profileForm });
      localStorageService.setData("user", profileForm);
      showToastMsg("success", t("User data updated successfully"));
      setFormChanged(false);
    }
    setSaving(false);
  }

  useEffect(() => {
    setProfileForm({ ...new User(), ...user });
  }, [user]);

  const handleFormChange = (event: any) => {
    let value = event.target.value;
    const name = event.target.name;
    setFormChanged(true);

    console.log(value);
    console.log(name);
    if (isAllTextSpaces(value)) {
      return;
    }
    if (name === "telephone") {
      value
        ? setTelephoneError(!telephoneRegex.test(value))
        : setTelephoneError(false);
    } else if (name === "companyEmail") {
      value ? setEmailError(!emailRegex.test(value)) : setEmailError(false);
    } else if (name === "firstName") {
      value = value.trim();
      value
        ? setFirstNameError(!nameRegex.test(value))
        : setFirstNameError(true);
    } else if (name === "lastName") {
      value = value.trim();
      value ? setLastNameError(!nameRegex.test(value)) : setLastNameError(true);
    } else if (name === "companyPostalCode") {
      value = value.trim();
      value
        ? setPostalCodeError(!digitOnly.test(value))
        : setPostalCodeError(false);
    } else if (name === "companyRegisterNumber") {
      value
        ? setCompanyRegisterNumberError(!telephoneRegex.test(value))
        : setCompanyRegisterNumberError(false);
    }

    const obj: any = {};
    obj[name] = value;
    setProfileForm({ ...profileForm, ...obj });
  };

  const onUploadImage = async (
    profilePicKey: string,
    profilePicURL: string
  ) => {
    const data = { ...profileForm, profilePicKey, profilePicURL };
    const res = await userService.updateUserInfo({ profilePicKey });
    if (res && res.msg === "Updated") {
      setUser({ ...data });
      setProfileForm({ ...data });
      localStorageService.setData("user", data);
      showToastMsg("success", t("PROFILE_PICTURE_UPDATED_SUCCESSFULLY"));
      setFormChanged(false);
    }
  };

  const onDeleteImg = async () => {
    const res = await userService.updateUserInfo({ profilePicKey: null });
    if (res && res.msg === "Updated") {
      const data = { ...profileForm, profilePicKey: "", profilePicURL: "" };
      setUser({ ...data });
      setProfileForm({ ...data });
      showToastMsg("success", t("PROFILE_PICTURE_DELETED_SUCCESSFULLY"));
    }
  };

  const handleCloseConfirmDialog = async (value: boolean) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      const response = await userService.deactivateAccount();
      if (response && response.message === "deactivated") {
        handleLogout();
        showToastMsg("success", t("ACCOUNT_DELETED_SUCCESSFULLY"));
      } else if (response.message) {
        showPopupToastMsg("error", t(response.message));
      } else {
        showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
      }
    }
  };

  const handleLogout = () => {
    const token = localStorageService.getData("token");
    userService.logout({ refresh_token: token.refresh_token });
    const lang = localStorageService.getData("lang");
    localStorageService.clearAll();
    localStorageService.setData("lang", lang);
    const favicon: any = getFaviconEl();
    favicon.href = "/icons/logos/pAIper-logo.svg";
    history.push("/login");
  };

  return (
    <section className="profile-management">
      <Box className="global__box__content mb-4 p-0">
        <Box className="title">
          <Typography variant="h6">{t("USER_DATA")}</Typography>
        </Box>
        <Box className="content">
          <Box className="row-col-3">
            <Typography variant="subtitle2">{t("PHOTO")}</Typography>
            <Typography variant="subtitle2">
              150x150px JPEG, PNG Image
            </Typography>
            <ProfilePictureComponent
              onUploadSuccess={onUploadImage}
              onDelete={onDeleteImg}
            />
          </Box>

          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("TITLE")}</Typography>
            <Box className="standard_text_input">
              <FormControl className="select-input w-100">
                {!profileForm.title && (
                  <InputLabel id="title">{t("TITLE")}</InputLabel>
                )}
                <Select
                  labelId="title"
                  id="title-select"
                  name="title"
                  variant="standard"
                  value={profileForm.title || ""}
                  onChange={handleFormChange}
                  IconComponent={ExpandMoreIcon}
                >
                  {userTitlesValue.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("FIRST_NAME")}</Typography>

            <Box className="standard_text_input">
              <TextField
                className="w-100"
                placeholder={t("FIRST_NAME")}
                variant="standard"
                name="firstName"
                value={profileForm.firstName || ""}
                error={firstNameError ? true : false}
                onChange={handleFormChange}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </Box>
          </Box>
          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("LAST_NAME")}</Typography>
            <Box className="standard_text_input">
              <TextField
                className="w-100"
                variant="standard"
                placeholder={t("lastName")}
                name="lastName"
                value={profileForm.lastName || ""}
                error={lastNameError ? true : false}
                onChange={handleFormChange}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </Box>
          </Box>

          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("COMPANY")}</Typography>
            <Box className="standard_text_input">
              <TextField
                className="w-100"
                variant="standard"
                placeholder={t("company name type of company")}
                name="companyName"
                value={profileForm.companyName}
                onChange={handleFormChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Box>
          </Box>

          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("PHONE_NUMBER")}</Typography>
            <Box className="standard_text_input">
              <TextField
                className="w-100"
                placeholder={t("PHONE_NUMBER")}
                variant="standard"
                name="telephone"
                value={profileForm.telephone || ""}
                error={telephoneError ? true : false}
                onChange={handleFormChange}
              />
            </Box>
          </Box>

          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("EMAIL")}</Typography>
            <Box className="standard_text_input">
              <TextField
                placeholder={t("EMAIL")}
                variant="standard"
                name="email"
                value={user.email}
                disabled
                className="w-100"
              />
            </Box>
          </Box>

          <Box className="text-right">
            <Button
              variant="contained"
              color="primary"
              className="btn-primary-theme"
              onClick={submit}
              disabled={
                telephoneError ||
                emailError ||
                firstNameError ||
                lastNameError ||
                saving ||
                postalCodeError ||
                companyRegisterNumberError ||
                !profileForm.companyName ||
                !fromChanged
              }
            >
              {!saving && <CheckIcon />} {t("save changes")}{" "}
              {saving && <CircularProgress color="inherit" size={20} />}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="global__box__content mb-4 p-0">
        <Box className="title">
          <Typography variant="h6">{t("PASSWORD")}</Typography>
        </Box>
        <ProfilePasswordComponent />
      </Box>

      {user.type !== "superAdmin" && (
        <Box className="global__box__content mb-4 p-0">
          <Box className="title">
            <Typography variant="h6">{t("DELETE_ACCOUNT")}</Typography>
          </Box>
          <Box className="content">
            <Typography variant="body1">{t("DELETE_ACCOUNT_DESC")}</Typography>

            <FormControlLabel
              className="checkbox-global mt-3"
              control={
                <Checkbox
                  onChange={(e) => setConfirmDeleteAccount(e.target.checked)}
                  name="clean"
                  size="small"
                />
              }
              label={t("CONFIRM_DELETE_ACCOUNT")}
            />

            <Box className="text-right">
              <Button
                variant="contained"
                color="primary"
                className="btn-primary-theme btn-danger"
                onClick={() => setShowConfirmModal(true)}
                disabled={!confirmDeleteAccount}
              >
                <CheckIcon />
                {t("DELETE_ACCOUNT")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmDialog
        show={showConfirmModal}
        onClose={handleCloseConfirmDialog}
        dialogTitle={t("confirm delete")}
        dialogBody={t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_ACCOUNT")}
        confirmBtnText={t("delete")}
        cancelBtn={true}
      />
      {/* <div className="personal-info">
        <SettingSectionTitleComponent
          title={t("USER_DATA")}
          subTitle={t("CHANGE_YOUR_PERSONAL_ACCOUNT_INFORMATION_HERE")}
        />

        <Box className="row">
          <Box className="col-5">
            <ProfilePictureComponent
              onUploadSuccess={onUploadImage}
              onDelete={onDeleteImg}
            />
          </Box>
        </Box>

        <div className="row">
          <div className="col-9 mb-40px">
            <div className="profile-card">
              <div className="card-content">
                <div className="mt-3 mb-4">
                  <div className="row no-gutters flex-wrap justify-content-between">
                    <div className={fullNav ? "w-100 mb-3" : "w-48"}>
                      <DefaultInputView
                        label={t("password")}
                        value="password-hint"
                      />
                    </div>
                    <div
                      className={"btn-container " + (fullNav ? "w-100" : "")}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className="btn-secondary-theme w-100 btn-password"
                        onClick={setShowPasswordModal.bind(null, true)}
                      >
                        <img src={icons.passwordPrimary} alt="" />{" "}
                        {t("change password")}
                      </Button>
                    </div>
                    <ChangePassword
                      show={showPasswordModal}
                      onClose={closePasswordModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user.group === "external" && (
            <div className="col-9 mb-40px">
              <div className="company-card">
                <div className="card-content">
                  <div className="mt-3 mb-4">
                    <div className="row no-gutters flex-wrap justify-content-between">
                      <div className="w-100 mb-3">
                        <TextField
                          className="w-100"
                          label={t("company name type of company")}
                          variant="filled"
                          name="companyName"
                          value={profileForm.companyName}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </div>
                      <div className="w-100 mb-3">
                        <TextField
                          className="w-100"
                          label={t("street house number")}
                          variant="filled"
                          name="companyStreet"
                          value={profileForm.companyStreet}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </div>
                      <div className="w-28 mb-3">
                        <TextField
                          className="w-100 overflow-hidden"
                          label={t("commercial register number")}
                          variant="filled"
                          name="companyRegisterNumber"
                          value={profileForm.companyRegisterNumber}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 15,
                          }}
                        />
                      </div>
                      <div className="w-20 mb-3">
                        <TextField
                          className="w-100"
                          label={t("postcode")}
                          variant="filled"
                          name="companyPostalCode"
                          value={profileForm.companyPostalCode}
                          onChange={handleFormChange}
                          error={postalCodeError ? true : false}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </div>
                      <div className="w-44 mb-3">
                        <TextField
                          className="w-100"
                          label={t("city")}
                          variant="filled"
                          name="companyCity"
                          value={profileForm.companyCity}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 25,
                          }}
                        />
                      </div>
                      <div className="w-100">
                        <TextField
                          className="w-100"
                          label="Email Office"
                          variant="filled"
                          name="companyEmail"
                          type="email"
                          value={profileForm.companyEmail}
                          error={emailError ? true : false}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </div>
                      {emailError && (
                        <div className="error-msg pt-1">
                          {t("invalid-email")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-9">
            <div className="footer d-flex flex-row justify-content-end align-items-end mt-4">
              <Button
                className="btn-primary-theme btn-save"
                variant="contained"
                color="primary"
                onClick={submit}
                disabled={
                  telephoneError ||
                  emailError ||
                  firstNameError ||
                  lastNameError ||
                  postalCodeError ||
                  companyRegisterNumberError ||
                  !fromChanged
                }
              >
                <CheckIcon /> {t("save changes")}
              </Button>
            </div>
          </div>
        </div>
      </div> */}

      {/* {user.role === "editor" && userCategories.length !== 0 && (
        <div className="user-category">
          <SettingSectionTitleComponent
            title={t("main processor responsibilities")}
            subTitle={t("main processor description")}
          />
          <div className="categories">
            {DefaultCategories.map((category: any, index: number) => {
              return workspaceCategories.map((cat: any, i: any) => {
                return (
                  <>
                    {cat.key === category.key && (
                      <OnboardingCategory
                        key={index}
                        name={cat.name}
                        src={category.src}
                        activeSrc={category.activeSrc}
                        borderNum={1}
                        active={userCategories.some(
                          (item: any) => item.key === cat.key
                        )}
                      />
                    )}
                  </>
                );
              });
            })}
          </div>
        </div>
      )} */}

      {user.role !== "coordinator" && user.type !== "superAdmin" && (
        <div className="contacts-section">
          <SettingSectionTitleComponent title={t("Contact us")} subTitle="" />
          {/* <ContactInfo /> */}
          <SupportBlockComponent />
        </div>
      )}
    </section>
  );
}
