import React, { createContext, useReducer } from "react";
import CaseReducer from "../reducers/case.reducer";
import { Case, UpdatedCase } from "../../types/case";

const initialState: any = {
  caseDetails: new Case(),
  updatedCaseDetails: new UpdatedCase(),
  caseDueDate: new Date(),
  loading: false,
  classificationLoading: false,
  communicationsLoading: false,
  workflowLoading: false,
  filesLoading: false,
  notesLoading: false,
  usersLoading: false,
  activeTab: 1,
  aiActivation: false,
  activationWorkflowAI: false,
  caseUsers: [],
  caseStatuses: [],
  caseCategories: [],
  lands: [],
  branches: [],
  areas: [],
  techniques: [],
  machineLearningTypes: [],
  algorithms: [],
  operations: [],
  caseAmounts: [],
  caseRelations: [],
  caseComments: [],
  caseQuestions: [],
  caseTotalQuestions: 0,
  caseTotalAnsweredQuestions: 0,
  completedStatusId: 15,
  caseFiles: [],
  caseNotes: [],
  caseDetailsUpdated: false,
  selectedFile: null,
  ignoreUpdateCase: 1,
};

export const CaseContext = createContext(initialState);
export const CaseProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(CaseReducer, initialState);

  const setCase = (caseDetails: Case) => {
    dispatch({
      type: "SET_CASE",
      payload: { caseDetails },
    });
  };

  const setUpdatedCase = (updatedCaseDetails: Case) => {
    dispatch({
      type: "SET_UPDATED_CASE",
      payload: { updatedCaseDetails },
    });
  };

  const setIgnoreUpdateCase = (ignoreUpdateCase: number) => {
    dispatch({
      type: "SET_IGNORE_UPDATE_CASE",
      payload: { ignoreUpdateCase },
    });
  };

  const setCaseDueDate = (caseDueDate: Case) => {
    dispatch({
      type: "SET_CASE_DUE_DATE",
      payload: { caseDueDate },
    });
  };

  const setCaseUsers = (caseUsers: any[]) => {
    dispatch({
      type: "SET_CASE_USERS",
      payload: { caseUsers },
    });
  };

  const setCaseAmounts = (caseAmounts: any[]) => {
    dispatch({
      type: "SET_CASE_AMOUNTS_OF_DAMAGE",
      payload: { caseAmounts },
    });
  };

  const setCaseCategories = (caseCategories: any[]) => {
    dispatch({
      type: "SET_CASE_Categories",
      payload: { caseCategories },
    });
  };

  const setLands = (lands: any[]) => {
    dispatch({
      type: "SET_LANDS",
      payload: { lands },
    });
  };

  const setAreas = (areas: any[]) => {
    dispatch({
      type: "SET_AREAS",
      payload: { areas },
    });
  };

  const setAlgorithms = (algorithms: any[]) => {
    dispatch({
      type: "SET_ALGORITHMS",
      payload: { algorithms },
    });
  };

  const setOperations = (operations: any[]) => {
    dispatch({
      type: "SET_OPERATIONS",
      payload: { operations },
    });
  };

  const setTechniques = (techniques: any[]) => {
    dispatch({
      type: "SET_TECHNIQUES",
      payload: { techniques },
    });
  };

  const setMachineLearningTypes = (machineLearningTypes: any[]) => {
    dispatch({
      type: "SET_MACHINE_LEARNING_TYPES",
      payload: { machineLearningTypes },
    });
  };

  const setBranches = (branches: any[]) => {
    dispatch({
      type: "SET_BRANCHES",
      payload: { branches },
    });
  };

  const setCaseRelations = (caseRelations: any[]) => {
    dispatch({
      type: "SET_CASE_RELATIONS",
      payload: { caseRelations },
    });
  };
  const setCaseComments = (caseComments: any[]) => {
    dispatch({
      type: "SET_CASE_COMMENTS",
      payload: { caseComments },
    });
  };

  const setCaseQuestions = (caseQuestions: any[]) => {
    dispatch({
      type: "SET_CASE_QUESTIONS",
      payload: { caseQuestions },
    });
  };

  const setCaseTotalQuestions = (totalQuestions: any[]) => {
    dispatch({
      type: "SET_CASE_TOTAL_QUESTIONS",
      payload: { totalQuestions },
    });
  };

  const setCaseTotalAnsweredQuestions = (totalAnsweredQuestions: any[]) => {
    dispatch({
      type: "SET_CASE_TOTAL_ANSWERED_QUESTIONS",
      payload: { totalAnsweredQuestions },
    });
  };

  const setCaseStatuses = (caseStatuses: any[]) => {
    dispatch({
      type: "SET_CASE_STATUSES",
      payload: { caseStatuses },
    });
  };

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      payload: { loading },
    });
  };

  const setClassificationLoading = (classificationLoading: boolean) => {
    dispatch({
      type: "SET_CLASSIFICATION_LOADING",
      payload: { classificationLoading },
    });
  };

  const setCommunicationsLoading = (communicationsLoading: boolean) => {
    dispatch({
      type: "SET_COMMUNICATIONS_LOADING",
      payload: { communicationsLoading },
    });
  };

  const setWorkflowLoading = (workflowLoading: boolean) => {
    dispatch({
      type: "SET_WORKFLOW_LOADING",
      payload: { workflowLoading },
    });
  };

  const setActiveTab = (activeTab: number) => {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: { activeTab },
    });
  };

  const setAIActivation = (aiActivation: boolean) => {
    dispatch({
      type: "SET_AI_ACTIVATION",
      payload: { aiActivation },
    });
  };

  const setActivationWorkflowAI = (activationWorkflowAI: number) => {
    dispatch({
      type: "SET_ACTIVATION_WORKFLOW_AI",
      payload: { activationWorkflowAI },
    });
  };

  const setCaseFiles = (caseFiles: any[]) => {
    dispatch({
      type: "SET_CASE_FILES",
      payload: { caseFiles },
    });
  };

  const setFilesLoading = (filesLoading: boolean) => {
    dispatch({
      type: "SET_FILES_LOADING",
      payload: { filesLoading },
    });
  };

  const setSelectedFile = (selectedFile: boolean) => {
    dispatch({
      type: "SET_SELECTED_FILE",
      payload: { selectedFile },
    });
  };

  const setCaseNotes = (caseNotes: any[]) => {
    dispatch({
      type: "SET_CASE_NOTES",
      payload: { caseNotes },
    });
  };

  const setUsersLoading = (usersLoading: boolean) => {
    dispatch({
      type: "SET_USERS_LOADING",
      payload: { usersLoading },
    });
  };

  const setNotesLoading = (notesLoading: boolean) => {
    dispatch({
      type: "SET_NOTES_LOADING",
      payload: { notesLoading },
    });
  };

  const setCaseDetailsUpdated = (caseDetailsUpdated: boolean) => {
    dispatch({
      type: "SET_CASE_DETAILS_UPDATED",
      payload: { caseDetailsUpdated },
    });
  };

  return (
    <CaseContext.Provider
      value={{
        ...state,
        setUpdatedCase,
        setCommunicationsLoading,
        setLoading,
        setWorkflowLoading,
        setCaseDueDate,
        setIgnoreUpdateCase,
        setCase,
        setActiveTab,
        setAIActivation,
        setActivationWorkflowAI,
        setCaseStatuses,
        setCaseUsers,
        setCaseAmounts,
        setCaseCategories,
        setLands,
        setAreas,
        setAlgorithms,
        setOperations,
        setTechniques,
        setMachineLearningTypes,
        setBranches,
        setCaseRelations,
        setCaseComments,
        setCaseQuestions,
        setCaseTotalQuestions,
        setCaseTotalAnsweredQuestions,
        setCaseFiles,
        setFilesLoading,
        setCaseNotes,
        setNotesLoading,
        setUsersLoading,
        setCaseDetailsUpdated,
        setClassificationLoading,
        setSelectedFile,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};
