import React, { useContext, useEffect, useState } from "react";
import "./change-keycontact.component.scoped.scss";
import { CaseContext } from "../../../../store";
import {
  Box,
  Button,
  CircularProgress,
  Radio,
  Typography,
} from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { CaseUser } from "../../../../types/case-user";
import { CasesService } from "../../../../services/cases/cases.service";
import { showPopupToastMsg, showToastMsg } from "../../../../helpers";

export const ChangeKeyContactDialog: React.FunctionComponent<{
  show: boolean;
  onClose: (tab: boolean) => void;
}> = ({ show, onClose }) => {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const { caseDetails, caseUsers, setCase } = useContext(CaseContext);
  const [userImageError, setUserImageError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [keyContactUserId, setKeyContactUserId] = useState(
    caseDetails.keyContactUserId
  );

  const handleUpdate = async () => {
    setSaving(true);
    setIsChanged(false);
    const res = await casesService.changeKeyContact(
      caseDetails.id,
      keyContactUserId
    );
    if (res.message === "Success") {
      const newKeyContactUser = caseUsers.find(
        (user: CaseUser) => +user.id === +keyContactUserId
      );
      setCase({
        ...caseDetails,
        keyContactUserId,
        keyContactUser: newKeyContactUser,
      });
      showToastMsg(
        "success",
        t("USECASE_DETAILS.KEYCONTACT_CHANGED_SUCCESSFULLY")
      );
    } else {
      showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
    setSaving(false);
    onClose(false);
  };

  useEffect(() => {
    setKeyContactUserId(caseDetails.keyContactUserId);
  }, [caseDetails]);

  return (
    <Modal
      show={show}
      onHide={onClose.bind(null, false)}
      centered
      dialogClassName="modal-user modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("USECASE_DETAILS.CHANGE_KEYCONTACT_OF_CASE")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="selected-users-container">
          {caseUsers.map((caseUser: CaseUser, index: any) => {
            return (
              <Box className="selected-user" key={index}>
                <Box className="user-info">
                  <Box className="user__profile__pic">
                    {caseUser.profilePicURL && !userImageError ? (
                      <img
                        src={caseUser.profilePicURL}
                        title={caseUser.firstName + " " + caseUser.lastName}
                        alt=""
                        onError={setUserImageError.bind(null, true)}
                      />
                    ) : (
                      <span className="text-uppercase">
                        {caseUser.firstName
                          ? caseUser.firstName[0] + caseUser.lastName[0]
                          : ""}
                      </span>
                    )}
                  </Box>
                  <Box className="user-name">
                    <span>
                      {caseUser.name ||
                        caseUser.firstName + " " + caseUser.lastName}
                    </span>
                    <small>{caseUser.email}</small>
                  </Box>
                </Box>
                <Box className="user-role">{t(caseUser.role)}</Box>
                <Box className="user-type">{t(caseUser.group)}</Box>
                <Radio
                  disabled={saving}
                  checked={+caseUser.id === +keyContactUserId}
                  onChange={(e: any) => {
                    setIsChanged(true);
                    setKeyContactUserId(e.target.value);
                  }}
                  value={caseUser.id}
                  name="radio-buttons"
                />
              </Box>
            );
          })}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Box className="d-flex w-100 align-items-center mb-2">
          <InfoIcon />
          <Typography className="mx-1" variant="body1">
            {t("CHANGE_KEY_USER_HINT")}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={onClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={saving || !isChanged}
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon />
              {t("save")}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
