import React, { useState } from "react";
import { DashboardTableCardProps } from "../../../../types/dashboard-table-card-props";
import "./dashboard-table-card.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { TableColumn } from "../../../../types/table-column";
import { formatDate } from "../../../../helpers";
import { history } from "react-router-guard";
import { CaseTabs } from "../../../../statics/caseTabs";
import { AIRolesByKey } from "../../../case/types/AI-roles";
import { Box, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";

export default function DashboardTableCard(props: DashboardTableCardProps) {
  const [t] = useTranslation();
  const { title, subTitle, columns, data, tableName, clickableRow } = props;
  const [userImageError, setUserImageError] = useState(false);

  const handleRowClick = (caseId: number, tab: number) => {
    if (clickableRow) {
      history.push(`/case/${caseId}`, {
        tab,
      });
    }
  };

  return (
    <Box className="dashboard-card  global__box__content">
      <Box className="card-details">
        <Typography variant="subtitle1" className="card-title">
          {title}
        </Typography>
        <Typography variant="body1" className="card-sub-title">
          {subTitle}
        </Typography>
      </Box>
      {data.length > 0 ? (
        <table>
          <thead>
            <tr>
              {columns.map((column: TableColumn, index: number) => {
                return (
                  <th
                    className="trim"
                    key={index}
                    style={{ width: column.width }}
                  >
                    {t(column.fieldName)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((row: any, rowIndex: number) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  key={rowIndex}
                  onClick={handleRowClick.bind(
                    null,
                    row.caseId,
                    CaseTabs.Description
                  )}
                >
                  {columns.map((column: TableColumn, columnIndex: number) => {
                    const {
                      type,
                      value,
                      secondaryValue,
                      translatedValue,
                    }: any = column;
                    return (
                      <td
                        className={type === "string" ? " trim" : ""}
                        key={columnIndex}
                        style={{ width: column.width }}
                      >
                        {type === "case_role" ? (
                          <Typography variant="body1">
                            {AIRolesByKey[row[value]].name}
                          </Typography>
                        ) : type === "object" ? (
                          <Box className="object">
                            <img
                              src={
                                row[value].iconKey
                                  ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                      row[value].iconKey
                                    ]
                                  : icons.categoryPrimary
                              }
                              alt=""
                            />
                            <Typography variant="body1" className="name">
                              {row[value].name}
                            </Typography>
                          </Box>
                        ) : type === "string" ? (
                          <Box
                            className={secondaryValue ? "double-column" : ""}
                          >
                            <Typography className="title trim" variant="h6">
                              {translatedValue
                                ? t(row[column.value])
                                : row[column.value]}
                            </Typography>
                            {secondaryValue && (
                              <Typography className="hint trim">
                                {translatedValue
                                  ? t(row[secondaryValue])
                                  : row[secondaryValue]}
                              </Typography>
                            )}
                          </Box>
                        ) : type === "status" ? (
                          <Typography variant="body1">
                            {row[value] === 0 ? t("pending") : t("completed")}
                          </Typography>
                        ) : type === "user" ? (
                          <Box className="user__profile__pic">
                            {row[value].profilePicURL && !userImageError ? (
                              <img
                                src={row[value].profilePicURL}
                                alt=""
                                onError={setUserImageError.bind(null, true)}
                              />
                            ) : (
                              <span className="text-uppercase">
                                {row[value].firstName[0] +
                                  row[value].lastName[0]}
                              </span>
                            )}
                          </Box>
                        ) : type === "progress" ? (
                          <Box className="progress__bar">
                            <span
                              className={
                                row[value] === 100 ? "completed" : "progress"
                              }
                              style={{ width: row[value] + "%" }}
                            ></span>
                          </Box>
                        ) : type === "date" ? (
                          <Typography variant="body1">
                            {formatDate(row[value], "DD.MM.YYYY")}
                          </Typography>
                        ) : type === "release" ? (
                          <span>
                            {row.major.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                            })}
                            .
                            {row.minor.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                            })}
                            .
                            {row.version.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                            })}
                          </span>
                        ) : type === "client" ? (
                          <Box className="client-cell">
                            <Box className="user__profile__pic">
                              <img src={row["img"]} alt="" />
                            </Box>
                            <Box className="client-info">
                              <Box className="client-name trim">
                                {row[value]}
                              </Box>
                              <Box className="branch-name trim">
                                {row[secondaryValue]}
                              </Box>
                            </Box>
                          </Box>
                        ) : type === "members" ? (
                          <Box className="members">
                            {row[value].map((item: any, index: number) => {
                              return (
                                <Box className="user__profile__pic" key={index}>
                                  {item.profilePicURL && !userImageError ? (
                                    <img
                                      src={
                                        item.profilePicURL ||
                                        icons.PLACEHOLDER.user
                                      }
                                      title={
                                        item.firstName + " " + item.lastName
                                      }
                                      alt=""
                                      onError={setUserImageError.bind(
                                        null,
                                        true
                                      )}
                                    />
                                  ) : (
                                    <span className="text-uppercase">
                                      {item.firstName
                                        ? item.firstName[0] + item.lastName[0]
                                        : ""}
                                    </span>
                                  )}
                                </Box>
                              );
                            })}
                            {row.total > 4 && (
                              <Box className="user__profile__pic">
                                <span>{`+${row[secondaryValue] - 4}`}</span>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <span className={type}>{row[value]}</span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {tableName === "PROJECTS" && (
            <tfoot>
              <tr>
                <td colSpan={4} className="action">
                  <span
                    className="link"
                    onClick={() => history.push("/ai-products")}
                  >
                    {t("ALL_PROJECTS")}
                  </span>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Box className="falback-wrapper">
          <img
            src={icons.fallbackDashboard}
            className="empty-chart-fallback"
            alt="empty-charts"
          />
        </Box>
      )}
    </Box>
  );
}
