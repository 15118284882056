import "./data-table.component.scoped.scss";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { TableProps } from "../../../types/table-props";
import { TableContentComponent } from "../table-content/table-content.component";
import CasesFilterComponent from "../../cases-filter/cases-filter.component";
import {
  CasesFilterRiskLevels,
  CasesFilterStags,
  casesSearchColumns,
} from "../../../statics/casesTableFilter";
import SearchIcon from "@material-ui/icons/Search";
import { icons } from "../../../statics/icons";
import { history } from "react-router-guard";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useOutsideAlerter from "../../../hooks/useOutisdeAlert";
import { GlobalContext } from "../../../store";

const filterRolesList = [
  { name: "COORDINATOR", value: "coordinator" },
  { name: "EDITOR", value: "editor" },
  { name: "AUDITOR", value: "auditor" },
  { name: "CONTRIBUTOR", value: "contributor" },
];

const SwitchUI = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 45,
  height: 24,
  padding: 0,
  margin: "0 5px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "2px 3px",
    transitionDuration: "300ms",
    top: 1,
    "&.Mui-checked": {
      transform: "translateX(21px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--accent-color)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      backgroundColor: "#E9E9EA",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const DataTableComponent = (props: TableProps) => {
  const [t] = useTranslation();
  const dropDownFilterRefs: any = useRef(null);
  const {
    data,
    options,
    pagination,
    columns,
    sort,
    paginate,
    loading,
    reset,
    title,
    subTitle,
    clickableRow,
    tableName,
    reload,
    onReload,
    refresh,
    caseRole,
    filter,
    onFilterChange,
  } = props;

  const { user } = useContext(GlobalContext);

  const [dropdownFilter, setDropdownFilter] = useState(false);
  const [roleFilter, setRoleFilter] = useState<any>({
    editor: false,
    auditor: false,
    coordinator: false,
    contributor: false,
  });

  const tools = {
    sort: (...args: any) => sort(...args),
  };

  const createUseCase = () => {
    history.push(`/create-case?role=${caseRole}`);
  };

  useOutsideAlerter(dropDownFilterRefs, () => {
    setDropdownFilter(false);
  });

  const onSearch = useCallback(
    debounce((query) => {
      onFilterChange({ ...filter, searchText: query });
    }, 600),
    [filter]
  );

  const onRoleFilter = (e: any) => {
    e.persist();
    const roleFilterObj = {
      ...roleFilter,
      [e.target.name]: e.target.checked,
    };
    setRoleFilter(roleFilterObj);
  };

  const onSubmitRoleFilter = () => {
    const value = Object.keys(roleFilter)
      .filter((key) => roleFilter[key])
      .join(",");
    onFilterChange({ ...filter, role: value });
    setDropdownFilter(false);
  };

  const onReserRoleFilter = () => {
    const roleFilterObj = {
      editor: false,
      auditor: false,
      coordinator: false,
      contributor: false,
    };
    setRoleFilter(roleFilterObj);
    onFilterChange({ ...filter, role: "" });
    setDropdownFilter(false);
  };

  return (
    <Box className="global__box__content table-container p-0">
      <Box className="table-head">
        <Box className="d-flex align-items-center flex-wrap">
          <Box className="table-title mr-3">{title}</Box>
          {subTitle && <Box className="table-subtitle mt-1">{subTitle}</Box>}
        </Box>

        {tableName === "users" && (
          <Box
            className="d-flex align-items-center user-form"
            style={{ gap: "1rem" }}
          >
            <Box className="standard_text_input" display="flex">
              <TextField
                variant="standard"
                type="text"
                autoComplete="off"
                onChange={(e: any) => onSearch(e.target.value)}
                placeholder={t("SEARCH_USER")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <FormControlLabel
              className="switch-global m-0"
              labelPlacement="start"
              label={t("ONLY_ACTIVE_USERS")}
              control={
                <SwitchUI
                  onChange={(e: any) =>
                    onFilterChange({
                      ...filter,
                      status: e.target.checked ? "verified" : null,
                    })
                  }
                  name="status"
                  size="small"
                  color="primary"
                  checked={filter.status === "verified" ? true : false}
                />
              }
            />

            <div className="dropdown-filter" ref={dropDownFilterRefs}>
              <Box
                className="filter-button"
                onClick={setDropdownFilter.bind(null, !dropdownFilter)}
              >
                {t("ALL_ROLES")}
                {dropdownFilter ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </Box>

              {dropdownFilter && (
                <Box className="filter-menu global__box__content">
                  <ul className="p-0 m-0">
                    {filterRolesList.map((role, index) => {
                      return (
                        <li key={index}>
                          <FormControlLabel
                            className="checkbox-global"
                            control={
                              <Checkbox
                                onChange={onRoleFilter}
                                name={role.value}
                                size="small"
                                checked={roleFilter[role.value]}
                              />
                            }
                            label={t(role.name)}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <hr
                    style={{
                      width: "199px",
                      marginLeft: "-0.5rem",
                    }}
                  />
                  <Box className="filter-actions d-flex mt-3 mb-2 align-items-center justify-content-between">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={onReserRoleFilter}
                    >
                      {t("RESET")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={onSubmitRoleFilter}
                    >
                      {t("SUBMIT")}
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </Box>
        )}

        {reload && (
          <Button
            variant="outlined"
            color="primary"
            className="btn-secondary-theme text-normal"
            onClick={onReload}
          >
            <CachedIcon className="mr-2" /> {t("reload list")}
          </Button>
        )}

        {tableName === "cases" && (
          <Box className="w-100 mt-4">
            <Box className="standard_text_input" display="flex">
              <TextField
                variant="standard"
                type="text"
                autoComplete="off"
                onChange={(e: any) => onSearch(e.target.value)}
                placeholder={t("FIND_AI_PROJECT")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="cases_filters w-100 mt-4">
              <Box className="cases_filters_content">
                <Typography
                  variant="body2"
                  className="mb-2"
                  style={{ color: "rgb(128, 128, 128)" }}
                >
                  {t("FILTER_BY_TOP_CATEGORIES")}
                </Typography>
                <Box className="cases_filters_chips">
                  <Box className="cases-filter-chip mr-4">
                    <Box
                      className={`chip ${filter?.all && "active"}`}
                      onClick={onFilterChange.bind(null, {
                        caseStage: null,
                        riskLevel: null,
                        all: filter?.all ? false : true,
                      })}
                    >
                      {t("ALL")}
                    </Box>
                  </Box>

                  <Box className="cases-filter-chip mr-4">
                    {CasesFilterStags.map((stag, index: number) => (
                      <Box
                        key={index}
                        className={`chip ${
                          filter?.caseStage === stag.key && "active"
                        }`}
                        onClick={onFilterChange.bind(null, {
                          caseStage:
                            filter?.caseStage === stag.key ? null : stag.key,
                          all: false,
                        })}
                      >
                        {t(stag.name)}
                      </Box>
                    ))}
                  </Box>

                  <Box className="cases-filter-chip">
                    {CasesFilterRiskLevels.map((risk: any, index: number) => (
                      <Box
                        className={`chip ${
                          filter?.riskLevel === risk.key && "active"
                        }`}
                        key={index}
                        onClick={onFilterChange.bind(null, {
                          riskLevel:
                            filter?.riskLevel === risk.key ? null : risk.key,
                          all: false,
                        })}
                      >
                        {t(risk.name)}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box
                className="cases_actions"
                display="flex"
                alignItems="center"
                gridGap=".5rem"
                justifyContent="end"
              >
                <CasesFilterComponent
                  onFiltersChange={onFilterChange}
                  searchableFields={casesSearchColumns}
                />
                {(user.type === "superAdmin" ||
                  user.role === "coordinator") && (
                  <Button
                    className="btn-primary-theme"
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={createUseCase}
                  >
                    <img
                      src={
                        caseRole === "PRODUCTS"
                          ? icons.PAGES.CASES.AIProductsWhite
                          : icons.PAGES.CASES.AIInventoryWhite
                      }
                      alt={title}
                      title={title}
                      className="mr-2"
                    />
                    {t("PAGES_HEAD.CREATE_NEW")}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <TableContentComponent
        options={options}
        data={data}
        columns={columns}
        tools={tools}
        loading={loading}
        reset={reset}
        paginate={paginate}
        pagination={pagination}
        clickableRow={clickableRow}
        refresh={refresh}
        tableName={tableName}
      />
    </Box>
  );
};
