import React from "react";
import { DashboardCardProps } from "../../../../types/dashboard-card-props";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import "./landscape-card.component.scoped.scss";
import RefreshCounterComponent from "../../../../components/refresh-counter/refresh-counter.component";

export default function LandscapeCard(props: DashboardCardProps) {
  const { title, total, icon, isDraggable, isDragging } = props;
  return (
    <div
      className={
        "dashboard-card global__box__content global__box__content_bg " +
        (isDragging ? "dragging" : "")
      }
    >
      <div
        className="draggable-icon"
        style={{ visibility: isDraggable ? "visible" : "hidden" }}
      >
        <ShuffleIcon />
      </div>
      <div className="d-grid align-items-center p-relative">
        <div className="card-icon">
          <img src={icon} alt="" />
        </div>
        <div className="card-details">
          <div className="card-total trim">
            <RefreshCounterComponent targetNumber={total} />
          </div>
          <div className="card-title trim">{title}</div>
        </div>
      </div>
    </div>
  );
}
