import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  showPopupToastMsg,
  showToastMsg,
  turncate,
} from "../../../../../helpers";
import { SettingsService } from "../../../../../services/settings/settings.service";
import { TenantForm } from "../../../../../types/tenant";
import ContactInfo from "../../../../../components/contact-info/contact-info.component";
import CompanyInfo from "../../../../../components/company-info/company-info.component";
import {
  passwordRegex,
  specialChar,
  capitalChar,
  digitChar,
  stringRegex,
} from "../../../../../statics/validators";
import "./workspace-management.component.scoped.scss";
import { FileSizes } from "../../../../../statics/file-sizes";
import { fileTypes } from "../../../../../statics/file-types";
import { WorkspacesService } from "../../../../../services/workspaces/workspaces.service";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../../../../../statics/alert-config";
import Swal from "sweetalert2";
import { GlobalContext } from "../../../../../store";
import { UserService } from "../../../../../services/user/user.service";
import { MapperService } from "../../../../../services/mapper/mapper.service";
import { LocalStorageService } from "../../../../../services/local-storage/local-storage";
import { CasesService } from "../../../../../services/cases/cases.service";
import { ConfirmDialog } from "../../../../../dialogs/confirm-dialog/confirm-dialog";

export default function WorkspaceManagement() {
  const [t] = useTranslation();
  const settingsService: SettingsService = new SettingsService();
  const workspaceService: WorkspacesService = new WorkspacesService();
  const userService: UserService = new UserService();
  const mapperService: MapperService = new MapperService();
  const localStorageService = new LocalStorageService();
  const casesService = new CasesService();
  const {
    workspaceImage,
    user,
    setWorkspaceImage,
    setUserTenants,
  } = useContext(GlobalContext);

  const [workspaceForm, setWorkspaceForm] = useState(new TenantForm());

  const [formChanged, setFormChanged] = useState(false);
  const [nameError, setNameError] = useState(false);
  const inputFileRef = useRef(null);
  const [confirmCleanWorkSpace, setConfirmCleanWorkSpace] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [saving, setSaving] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [capsLock, setCapsLock] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    getWorkspace();
    getPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPassword = async () => {
    const defaultPassword = await settingsService.getDefaultPassword();
    setPassword(defaultPassword.value || "");
  };

  const changePassword = async () => {
    setPasswordChanged(false);
    setSaving(true);
    const res = await settingsService.setDefaultPassword(password);
    setSaving(false);
    if (res && res.msg === "updated") {
      showToastMsg("success", t("standard password updated successfully"));
    }
  };

  const getWorkspace = async () => {
    const workspaceInfo = await settingsService.getWorkspaceInfo();
    setWorkspaceForm(workspaceInfo.tenant);
  };

  const clickUpload = () => {
    const element: any = inputFileRef.current;
    element.click();
  };

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const validateFiles = async (files: any[]) => {
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];
      if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(fileName, 20)}</div> 
                    is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        if (fileType.includes("jpg") || fileType.includes("png")) {
          setFormChanged(true);
          file.src = await getImagePreview(file);
          setWorkspaceImage(file);
        } else {
          errorsMsg.push("Only jpg/jpeg/png file types are supported");
        }
      }
    }
    showAlertMsg(errorsMsg);
  };

  async function getImagePreview(file: File) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  }

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  const submit = async () => {
    setFormChanged(false);
    let data = { logoUrl: workspaceForm.logoUrl, name: workspaceForm.name };
    if (workspaceImage.src) {
      const workspacePicForm = new FormData();
      workspacePicForm.append("image", workspaceImage, workspaceImage.name);
      workspacePicForm.append("clientName", workspaceForm.configurationName);
      workspacePicForm.append("type", "logo");
      const workspaceImageRes = await workspaceService.uploadImage(
        workspacePicForm
      );
      if (workspaceImageRes.url) {
        data.logoUrl = workspaceImageRes.url;
      } else {
        showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
        return;
      }
    }
    const resWorkspace = await settingsService.updateWorkspaceInfo(data);
    if (resWorkspace.message === "success") {
      const tenants = await userService.getUserTenants();
      const mappedTenants = mapperService.mapTenants(tenants);
      setUserTenants(mappedTenants);
      setWorkspaceForm({
        ...workspaceForm,
        name: data.name,
        logoUrl: data.logoUrl,
      });
      localStorageService.setData("tenantName", data.name);
      localStorageService.setData("tenantIcon", data.logoUrl);
      setWorkspaceImage({});
      showToastMsg("success", t("workspace information updated successfully"));
    } else {
      showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  const cleanWorkSpace = async () => {
    setSaving(true);
    const res = await casesService.deleteAll();
    if (res.message === "Success") {
      showToastMsg("success", t("WORKSPACE_CLEANUP_SUCCESSFULLY"));
    }
    setSaving(false);
  };

  const handleCloseConfirmDialog = (value: boolean) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      cleanWorkSpace();
    }
  };

  return (
    <section className="workspace-management">
      <Box className="global__box__content mb-4 p-0">
        <Box className="title">
          <Typography variant="h6">{t("WORKSPACE_SPECIFICATIONS")}</Typography>
        </Box>
        <Box className="content">
          <Box className="row-col-3">
            <Typography variant="subtitle2">{t("logo")}</Typography>
            <Typography variant="subtitle2">
              Logo 150x150px JPEG, PNG Image
            </Typography>
            <Box className="logo-update">
              <img
                src={
                  workspaceImage.src
                    ? workspaceImage.src
                    : workspaceForm.logoUrl
                    ? workspaceForm.logoUrl
                    : "/icons/onboarding/cameraIcon.svg"
                }
                alt="camera"
                className={workspaceForm.logoUrl ? "image-full" : "camera-icon"}
                onClick={clickUpload}
              />
              <input
                type="file"
                ref={inputFileRef}
                onChange={onFileChange}
                style={{ display: "none" }}
                multiple
                accept={fileTypes}
              />
            </Box>
          </Box>
          <Box className="row-col-2">
            <Typography variant="subtitle2">{t("workspace name")}</Typography>

            <Box className="standard_text_input">
              <TextField
                type="text"
                placeholder={t("workspace name")}
                variant="standard"
                name="workspace"
                className="w-100"
                autoComplete="off"
                value={workspaceForm.name}
                error={nameError}
                onChange={(e) => {
                  setFormChanged(true);
                  e.target.value
                    ? setNameError(!stringRegex.test(e.target.value))
                    : setNameError(true);
                  setWorkspaceForm({
                    ...workspaceForm,
                    name: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>
          <Box className="row-col-2">
            <Typography variant="subtitle2">
              {t("configuration name")}
            </Typography>
            <Box className="standard_text_input">
              <TextField
                placeholder={t("configuration name")}
                variant="standard"
                name="configurationName"
                value={workspaceForm.configurationName}
                disabled
                className="w-100"
              />
            </Box>
          </Box>
          <Box className="text-right">
            <Button
              variant="contained"
              color="primary"
              className="btn-primary-theme"
              onClick={submit}
              disabled={
                !formChanged || workspaceForm.name.length === 0 || nameError
              }
            >
              {t("update workspace")}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="global__box__content mb-4 p-0">
        <Box className="title">
          <Typography variant="h6">{t("standard passwort")}</Typography>
          {/* <Typography variant="body1">
            {t("standard passwort desc")}
          </Typography> */}
        </Box>
        <Box className="content">
          <Box className="row-col-2 align-items-start">
            <Typography variant="subtitle2" className="mt-3">
              {t("PASSWORD")}
            </Typography>

            <Box className="standard_text_input">
              <TextField
                className="w-100"
                variant="standard"
                type={passwordVisibility ? "text" : "password"}
                placeholder={t("default password")}
                value={password}
                autoComplete="off"
                name="newPassword"
                onChange={(e) => {
                  setPassword(e.target.value);
                  e.target.value
                    ? setPasswordError(!passwordRegex.test(e.target.value))
                    : setPasswordError(false);
                }}
                onKeyDown={onKeyDown}
                onInput={setPasswordChanged.bind(null, true)}
                autoFocus={false}
                error={passwordError ? true : false}
                inputProps={{
                  minLength: 8,
                }}
                InputProps={{
                  autoComplete: "one-time-code",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="toggle-password"
                        aria-label="toggle password visibility"
                        onClick={setPasswordVisibility.bind(
                          null,
                          !passwordVisibility
                        )}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passwordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="body1" className="my-2">
                {t("Set a default password that new users initially receive")}
              </Typography>
              {capsLock && (
                <div className="error-msg capsLock">
                  {t("caps lock is turned on")}
                </div>
              )}
              <div className="error-msg pt-1">
                <Typography
                  variant="body2"
                  style={{
                    color:
                      password.length < 8 || password.length > 15
                        ? ""
                        : "#a6ba8d",
                  }}
                >
                  {t("length 8 to 15 characters")}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: specialChar.test(password) ? "#a6ba8d" : "" }}
                >
                  {t("at least 1 special character")}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: capitalChar.test(password) ? "#a6ba8d" : "" }}
                >
                  {t("at least 1 capital letter")}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: digitChar.test(password) ? "#a6ba8d" : "" }}
                >
                  {t("at least 1 digit")}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box className="text-right">
            <Button
              variant="contained"
              color="primary"
              className="btn-primary-theme"
              onClick={changePassword}
              disabled={!passwordChanged || passwordError || saving}
            >
              {t("save password")}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="global__box__content mb-4 p-0">
        <Box className="title">
          <Typography variant="h6">{t("company details")}</Typography>
          {/* <Typography variant="body1">{t("company details desc")}</Typography> */}
        </Box>
        <Box className="content">
          <CompanyInfo />
        </Box>
      </Box>

      {user.type === "superAdmin" && (
        <Box className="global__box__content mb-4 p-0">
          <Box className="title">
            <Typography variant="h6">{t("CLEANUP_WORKSPACE_TITLE")}</Typography>
          </Box>
          <Box className="content">
            <Typography variant="body1">
              {t("CLEANUP_WORKSPACE_DESC")}
            </Typography>

            <FormControlLabel
              className="checkbox-global mt-3"
              control={
                <Checkbox
                  onChange={(e: any) =>
                    setConfirmCleanWorkSpace(e.target.checked)
                  }
                  name="clean"
                  size="small"
                />
              }
              label={t("CONFIRM_CLEANING_WORKSPACE")}
            />

            <Box className="text-right">
              <Button
                variant="contained"
                color="primary"
                className="btn-primary-theme btn-danger"
                onClick={() => setShowConfirmModal(true)}
                disabled={saving || !confirmCleanWorkSpace}
              >
                {t("CLEANUP_WORKSPACE")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <div className="contacts-section">
        <div className="section-info">
          <Typography variant="h6">{t("Contact us")}</Typography>
        </div>
        <ContactInfo />
      </div>

      <ConfirmDialog
        show={showConfirmModal}
        onClose={handleCloseConfirmDialog}
        dialogTitle={t("confirm delete")}
        dialogBody={t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_CASES_IN_WORKSPACE")}
        confirmBtnText={t("delete")}
        cancelBtn={true}
      />
    </section>
  );
}
