import "./table-content.component.scoped.scss";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableHeaderComponent } from "../table-header/table-header.component";
import { TableContentProps } from "../../../types/table-content-props";
import { TableRowComponent } from "../table-row/table-row.component";
import { TableFooterComponent } from "../table-footer/table-footer.component";
import { tableHeightSetter } from "../../../helpers";
import { CasesContext } from "../../../store";
import { Box, CircularProgress } from "@material-ui/core";

export const TableContentComponent = (props: TableContentProps) => {
  const [t] = useTranslation();
  const { setCases, cases } = useContext(CasesContext);
  const {
    options,
    columns,
    data,
    tools,
    paginate,
    pagination,
    loading,
    reset,
    clickableRow,
    refresh,
    tableName,
  } = props;

  const selectCase = (checked: boolean, row: any) => {
    for (let item of cases) {
      if (row.id === item.id) {
        item.checked = checked;
      }
    }
    setCases(cases);
  };

  const selectCases = (checked: boolean) => {
    for (let item of cases) {
      item.checked = checked;
    }
    setCases(cases);
  };

  useEffect(() => {
    tableHeightSetter();
    window.onresize = function () {
      tableHeightSetter();
    };
  }, [loading, data]);

  return (
    <Box className="ov">
      <table style={{ minHeight: data && data.length === 0 ? "20rem" : "" }}>
        <thead>
          <TableHeaderComponent
            columns={columns}
            loading={loading}
            options={options}
            tools={tools}
            selectCases={selectCases}
          />
        </thead>
        <tbody>
          {loading && !data?.length && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                <CircularProgress />
              </td>
            </tr>
          )}
          {data && data.length === 0 && !loading && (
            <tr>
              <td className="text-center py-5" colSpan={columns.length}>
                {t("NO_DATA_AVAILABLE")}
              </td>
            </tr>
          )}
          {data.map((row: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <TableRowComponent
                  key={index}
                  data={row}
                  columns={columns}
                  clickableRow={clickableRow}
                  refresh={refresh}
                  selectedCase={selectCase}
                  tableName={tableName}
                />
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            {!reset && (
              <td colSpan={columns.length}>
                <TableFooterComponent
                  paginate={paginate}
                  pagination={pagination}
                />
              </td>
            )}
          </tr>
        </tfoot>
      </table>
    </Box>
  );
};
