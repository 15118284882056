import React from "react";
import "./dashboard-third-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardTableCard from "../dashboard-table-card/dashboard-table-card.component";
import { Box, Typography } from "@material-ui/core";
import { LocalStorageService } from "../../../../services/local-storage/local-storage";

export default function DashboardThirdBlock(props: any) {
  const { latestCases, user } = props;
  const [t] = useTranslation();
  const localStorage = new LocalStorageService();

  return (
    <Box className="cards__content mb-5">
      <Box className="dashboard-card  global__box__content">
        <Box className="card-details">
          <Typography variant="subtitle1" className="card-title">
            {t("ABOUT")}
          </Typography>
        </Box>
        <Box className="user-info">
          <Box className="item">
            <Typography variant="body1">{t("NAME")}:</Typography>
            <Typography variant="body1" className="text-capitalize">
              {user.firstName + " " + user.lastName}
            </Typography>
          </Box>
          <Box className="item">
            <Typography variant="body1">{t("WORKSPACE")}:</Typography>
            <Typography variant="body1">
              {localStorage.getData("tenantName")}
            </Typography>
          </Box>
          <Box className="item">
            <Typography variant="body1">{t("ROLE")}:</Typography>
            <Typography variant="body1">
              {user.role ? t(user.role) : t("COORDINATOR")}
            </Typography>
          </Box>
          <Box className="item">
            <Typography variant="body1">{t("COMPANY")}:</Typography>
            <Typography variant="body1">{user.companyName}</Typography>
          </Box>
          {user.telephone && (
            <Box className="item">
              <Typography variant="body1">{t("PHONE_NUMBER")}:</Typography>
              <Typography variant="body1">{user.telephone}</Typography>
            </Box>
          )}
          <Box className="item">
            <Typography variant="body1">{t("EMAIL")}:</Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
        </Box>
      </Box>

      {latestCases.data && (
        <Box className="card__content">
          <DashboardTableCard
            title={t(latestCases.title)}
            subTitle={t(latestCases.subTitle)}
            columns={latestCases.columns}
            tableName={latestCases.tableName}
            data={latestCases.data}
          />
        </Box>
      )}
    </Box>
  );
}
