import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./tab-content-head.component.scoped.scss";
// import { history } from "react-router-guard";
// import { PAGE_ROLES } from "../../../types/AI-roles";
// import { icons } from "../../../../../statics/icons";

interface tabContentHeadProps {
  title: string;
  subTitle: string;
  logo?: string;
}

const TabContentHeadComponent = (props: tabContentHeadProps) => {
  const { title, subTitle } = props;
  // const [pageLogo, setPageLogo] = useState(icons.AI_ASSISTENT);

  // useEffect(() => {
  //   if (history.location.search.includes(PAGE_ROLES.PRODUCTS)) {
  //     setPageLogo(icons.PAGES.HEAD.AI_PRODUCTS);
  //   } else if (history.location.search.includes(PAGE_ROLES.INVENTORY)) {
  //     setPageLogo(icons.PAGES.HEAD.AI_INVENTORY);
  //   } else if (history.location.search.includes(PAGE_ROLES.USER)) {
  //     setPageLogo(icons.PAGES.HEAD.AI_DEMAND);
  //   }
  //   // eslint-disable-next-line
  // }, []);
  return (
    <Box className="head">
      <Box>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
        <Typography variant="subtitle2" style={{ color: "var(--grey-text)" }}>
          {subTitle}
        </Typography>
      </Box>
      {/* <img src={logo ? logo : pageLogo} alt="" /> */}
    </Box>
  );
};

export default TabContentHeadComponent;
