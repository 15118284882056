import React, { useContext, useEffect, useState } from "react";
import { SettingsService } from "../../../../../services/settings/settings.service";
import { DataTableComponent } from "../../../../../components/table/data-table/data-table.component";
import { defaultItems } from "../../../../../statics/pagination";
import { MapperService } from "../../../../../services/mapper/mapper.service";
import { LogsContext } from "../../../../../store/providers/logs.provider";
import { useTranslation } from "react-i18next";

export const LogfileContent = () => {
  const [t] = useTranslation();
  const settingsServices = new SettingsService();
  const mapperService = new MapperService();
  const emptyArr: any = [];
  const {
    logs,
    columns,
    setLogs,
    setPagination,
    options,
    pagination,
    sort,
  } = useContext(LogsContext);

  const [loading, setLoading] = useState(true);

  const fetchData = async (filter?: any) => {
    setLogs(emptyArr);
    setLoading(true);
    const response = await settingsServices.getLogs(filter);
    const { readyLogs, paginationObj } = mapperService.mapLogs(response);
    setLogs(readyLogs);
    setPagination(paginationObj);
    setLoading(false);
  };

  const sortTable = (fieldName: string, sortDirection: string) => {
    const params = { fieldName, sortDirection };
    const filterObj = {
      page: 1,
      items: pagination.items,
      sort: fieldName,
      order: sortDirection,
    };
    fetchData(filterObj);
    return sort(params);
  };

  const paginateTable = (pagination: any) => {
    const { items, currentPage } = pagination;
    const params = {
      items,
      page: currentPage,
      sort: options.sortColumn,
      order: options.sortDirection,
    };
    fetchData(params);
  };

  useEffect(() => {
    const paginationParams = { items: defaultItems, page: 1 };
    fetchData(paginationParams);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="table-section">
        <DataTableComponent
          tableName="logs"
          loading={loading}
          columns={columns}
          data={logs}
          options={options}
          sort={sortTable}
          pagination={pagination}
          paginate={paginateTable}
          title={t("protocol list")}
          subTitle={`${pagination.total} ${t("entries")}`}
        />
      </div>
    </>
  );
};
