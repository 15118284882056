import { history } from "react-router-guard";
import axios from "axios";
import { config } from "../../config";
import { LocalStorageService } from "../local-storage/local-storage";
import { User } from "../../types/user";

export class AuthService {
  private localStorageService: LocalStorageService = new LocalStorageService();

  public account = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "auth/me");
      return response.data;
    } catch (error) {
      this.localStorageService.clearAll();
      history.push("/login");
      return new User();
    }
  };

  public myTenants = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "auth/me/tenants");
      return response.data;
    } catch (error) {
      return [];
    }
  };
}
