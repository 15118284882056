import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import "./onboarding-admin-account.component.scoped.scss";
import { icons } from "../../../../../statics/icons";
import { GlobalContext } from "../../../../../store";
import { WorkspacesService } from "../../../../../services/workspaces/workspaces.service";
import { UserService } from "../../../../../services/user/user.service";
import { showPopupToastMsg, showToastMsg } from "../../../../../helpers";
import { useTranslation } from "react-i18next";
import { history } from "react-router-guard";
import { LocalStorageService } from "../../../../../services/local-storage/local-storage";

const workspaceService: WorkspacesService = new WorkspacesService();
const userService: UserService = new UserService();
const localStorageService: LocalStorageService = new LocalStorageService();

const OnboardingAdminAccount = ({ nextTab, prevTab }: any) => {
  const {
    setWorkspaceForm,
    workspaceForm,
    contactInfoForm,
    OnboardingLands,
    OnboardingBranches,
    OnboardingCategories,
    OnboardingAreas,
    OnboardingAlgorithms,
    OnboardingOperations,
    OnboardingTechniques,
    OnboardingMachineLearning,
    reporterImage,
    workspaceImage,
    setWorkspaceImage,
  } = useContext(GlobalContext);
  const [t] = useTranslation();
  const [saving, setSaving] = useState(false);
  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setWorkspaceForm({ ...workspaceForm, ...obj });
  };

  const handleSubmit = async () => {
    setSaving(true);
    const adminPicForm = new FormData();
    const workspacePicForm = new FormData();

    adminPicForm.append("image", reporterImage, reporterImage.name);
    adminPicForm.append("clientName", workspaceForm.configurationName);
    adminPicForm.append("type", "admin-pictures");
    workspacePicForm.append("image", workspaceImage, workspaceImage.name);
    workspacePicForm.append("clientName", workspaceForm.configurationName);
    workspacePicForm.append("type", "logo");

    const workspaceImageRes = await workspaceService.uploadImage(
      workspacePicForm
    );
    const adminImageRes = await workspaceService.uploadImage(adminPicForm);

    if (workspaceImageRes.url && adminImageRes.url) {
      const mappedCategories = OnboardingCategories.map((cat: any) => {
        return {
          name: cat.name,
          prefix: cat.prefix,
          translation: cat.translation,
          key: cat.key,
          slug: cat.slug,
        };
      });

      const newContactInfoForm = {
        ...contactInfoForm,
        internalAdminNumber:
          workspaceForm.countryCode + " " + contactInfoForm.internalAdminNumber,
      };

      const bodyObj = {
        workspace: workspaceForm.workspace,
        contactInfo: newContactInfoForm,
        configurationName: workspaceForm.configurationName,
        lands: OnboardingLands,
        branches: OnboardingBranches,

        areas: OnboardingAreas,
        algorithms: OnboardingAlgorithms,
        operations: OnboardingOperations,
        techniques: OnboardingTechniques,
        machineLearning: OnboardingMachineLearning,

        categories: mappedCategories,
        clientName: workspaceForm.clientName,
        reporterUrl: workspaceForm.reporterUrl,
        logoUrl: workspaceImageRes.url,
        adminPictureUrl: adminImageRes.url,
      };

      const res = await workspaceService.addNewWorkspace(bodyObj);

      if (res.message === "SUCCESS" && res.tenantId) {
        await addAdminUser(res.tenantId);
        showToastMsg("success", t("Workspace created successfully"));
        localStorageService.setData("tenantId", res.tenantId);
        localStorageService.setData("tenantIcon", workspaceImageRes.url);
        localStorageService.setData("tenantName", workspaceForm.workspace);
        setWorkspaceImage({});
        history.replace("/landscape");
      } else {
        showPopupToastMsg("error", res.message);
      }
    }
    setSaving(false);
  };

  const addAdminUser = async (tenantId: number) => {
    const data: any = {
      firstName: capitalize(workspaceForm.adminFirstName),
      lastName: capitalize(workspaceForm.adminLastName),
      email: workspaceForm.adminEmail,
      role: "superUser",
      group: "internal",
      type: "coordinator",
      plan: 12,
      company: workspaceForm.clientName,
      defPassChecked: true,
    };

    const res = await userService.addUser({ users: [data] }, tenantId);

    if (res && res.users) {
      if (res && res.users.length && res.users[0].message === "SUCCESS") {
        showToastMsg("success", t("USER_ADD_SUCCESSFULLY"));
      } else if (
        res &&
        res.users.length &&
        res.users[0].message === "EXISTING"
      ) {
        showPopupToastMsg("error", t("User exists already"));
      } else {
        showPopupToastMsg("error", t("System error, please try again later"));
      }
    } else {
      showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>
      <h1 className="main-header">Create Coordinator Account</h1>
      <div className="sec-header">
        You’re almost done! Tell us some details for your coordinator account.{" "}
      </div>
      {/* <div className="section-name mb-3">Send an email invitation…</div> */}
      <div className="d-flex flex-row justify-content-start align-items-center w-100 mb-4">
        <TextField
          style={{ width: "230px", marginRight: "20px" }}
          label="First Name"
          variant="filled"
          name="adminFirstName"
          value={workspaceForm.adminFirstName}
          onChange={handleChange}
        />

        <TextField
          style={{ width: "230px", marginRight: "20px" }}
          label="Last Name"
          variant="filled"
          name="adminLastName"
          value={workspaceForm.adminLastName}
          onChange={handleChange}
        />

        <TextField
          style={{ width: "230px", marginRight: "20px" }}
          label="Email"
          variant="filled"
          name="adminEmail"
          value={workspaceForm.adminEmail}
          onChange={handleChange}
        />
      </div>

      <div className="notes-col">
        <div className="d-flex">
          <div className="info-icon">
            <img src={icons.info} alt="" />
          </div>
          <div className="info-note">
            {/* This user will be able to create other users and changes settings. */}
            This user will have all permissions on this workspace.
          </div>
        </div>
      </div>

      <div className="mb-5">
        <Grid container item xs={12} spacing={2}>
          <Grid container item justify="space-between">
            <Box>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  prevTab();
                }}
              >
                previous
              </Button>
            </Box>
            <Box width="240px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={
                  saving ||
                  workspaceForm.adminFirstName.length === 0 ||
                  workspaceForm.adminEmail.length === 0 ||
                  workspaceForm.adminLastName.length === 0
                }
              >
                {saving ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>Invite & Get Started</>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OnboardingAdminAccount;
