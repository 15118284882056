import moment from "moment";
import i18n from "../i18n";
import "moment/locale/de";
import { LangService } from "../services/lang/lang.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../statics/alert-config";

const langService: LangService = new LangService();
const sanitizeHtml = require("sanitize-html");

export const tableDate = (date: Date) => {
  const momentDate = moment(new Date(date));
  const week = momentDate.week() + 1;
  const formatedDate = momentDate.format("DD.MM.YYYY");
  const dayName: String = momentDate.format("dddd");
  const formatedDayName =
    dayName[0].toLocaleUpperCase() + dayName.substr(1, dayName.length);
  const tranlsatedDayName = i18n.t(formatedDayName);
  const outputDay = tranlsatedDayName.substr(0, 2);
  return { week, formatedDate, outputDay };
};

export const formattedTableDateString = (date: Date) => {
  const { week, outputDay, formatedDate } = tableDate(date);
  const weekHeadName = i18n.t("cw");
  return `${weekHeadName} ${week} - ${outputDay}, ${formatedDate}`;
};

export const arrayToString = (tasks: any[]) => {
  const tasksNames = tasks.map((item) => item.name);
  return tasksNames.join(", ");
};

export const tableHeightSetter = () => {
  setTimeout(() => {
    try {
      const headerElHeight =
        document.querySelector(".main-header")?.clientHeight || 0;
      const bodyHeight = document.querySelector("body")?.clientHeight || 0;
      const tableEl: any = document.querySelector(".table-content");
      const height = bodyHeight - (headerElHeight + 325);
      // 620 for 10 rows per page , every row 62 height.
      tableEl.style.minHeight = `${height < 620 ? 620 : height}px`;
    } catch (error) {
      // handle error
    }
  }, 0);
};

const timeDigitCheck = (item: string) => {
  return item.length < 2 ? `0${item}` : item;
};

export const timeFormat = ({ hours, minutes }: any) => {
  return `${timeDigitCheck(String(hours || 0))}:${timeDigitCheck(
    String(minutes || 0)
  )}`;
};

export const addLeadingZero = (value: number = 0) => {
  return value.toString().length === 1 ? `0${value}` : value;
};

export const timeConvert = (num: number) => {
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return { rhours, rminutes };
};

export const formatDate = (date: any, format: string) => {
  if (date && format) {
    const momentDate = moment(new Date(date));
    const lang = langService.getCurrentLang();
    moment.locale(lang);
    const formatedDate = momentDate.format(format);
    return formatedDate;
  }
};

export const deleteEmptyProp = (obj: any = {}) => {
  const object = { ...obj };
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const element = object[key];
      if (!element) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const limit = (text: string, length: number) => text?.substr(0, length);

export const turncate = (text: string = "", length: number) => {
  if (text.length > length) {
    return text.substr(0, length) + "...";
  } else {
    return text.substr(0, length);
  }
};

export const sanitize = (dirty: any) => ({
  __html: sanitizeHtml(dirty, {
    allowedTags: ["b", "i", "em", "strong", "u", "p", "br"],
  }),
});

export const replaceTags = (text: string = "") => {
  return text.replace(/(<([^>]+)>)/gi, "");
};

// deep clone (array of objects - array of arrays)
export const deepClone = (arr: any[] = []): any[] =>
  arr.map((item) =>
    Array.isArray(item)
      ? deepClone(item)
      : typeof item === "object"
      ? { ...item }
      : item
  );

export const isAllTextSpaces = (text: string = "") => {
  const regex = /^\s+$/;
  // check if user write spaces at first
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const isStringHasWhiteSpace = (text: string = "") => {
  const regex = /\s/;
  // check if user write spaces at first
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const showToastMsg = (icon: string, title: string) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon,
    title,
    ...alertToastConfig,
  });
};

export const showPopupToastMsg = (icon: any, title: string) => {
  Swal.fire({
    title,
    icon,
    position: "center",
    confirmButtonColor: "#b444cb",
  });
};

export const splitUserName = (name: string = "") => {
  const nameArr = name.split(" ");
  return {
    firstName: nameArr[0],
    lastName: nameArr[1],
  };
};

export const isValidLength = (text: string, maxChar: number) => {
  return text.length > 0 && text.length <= maxChar;
};

export const getFileExtension = (type: string, fileName?: string): string => {
  switch (type) {
    case "image/bmp":
      return ".bmp";
    case "image/jpeg":
      return fileName?.includes(".jpeg") ? ".jpeg" : ".jpg";
    case "image/jpg":
      return ".jpg";
    case "image/png":
      return ".png";
    case "text/xml":
      return ".xml";
    default:
      return "";
  }
};

export const base64ToFile = (
  url: string,
  filename: string,
  mimeType: string
) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
