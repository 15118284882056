import "./side-menu-items.component.scoped.scss";
import React, { useState, useContext } from "react";
import { history } from "react-router-guard";
import { useTranslation } from "react-i18next";
import { sideMenuItems } from "../../../../statics/sidenav-items";
import { GlobalContext } from "../../../../store";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { config } from "../../../../config";
import { Box, Typography } from "@material-ui/core";

export const SideMenuItemsComponent = () => {
  const [t] = useTranslation();

  const routeName = history.location.pathname;
  const [openSubItems, setOpenSubItems] = useState(false);
  const [itemHover, setItemHover] = useState("");
  const { fullNav, user, setFullNav } = useContext(GlobalContext);

  const openNav = () => {
    setFullNav(true);
    document.getElementsByTagName("body")[0].classList.add("full-nav");
  };

  const closeNav = () => {
    setFullNav(false);
    document.getElementsByTagName("body")[0].classList.remove("full-nav");
  };

  const navigate = (item: any) => {
    if (item.path) {
      if (item.type === "link") {
        window.open(item.path, "_blank");
      } else {
        history.push(item.path);
      }
    } else if (item.children && !fullNav) {
      setFullNav(true);
      setOpenSubItems(true);
    } else {
      history.push(item.path);
    }
  };

  return (
    <Box className={`side__menu__items ${fullNav ? "full" : ""}`}>
      {sideMenuItems.map((item, itemIndex) => {
        return (
          <Box key={itemIndex} className="side__menu__component">
            <Typography
              variant="body1"
              style={{
                color: "#636674",
                transition: "all 0.3s",
                textTransform: "capitalize",
              }}
              className={`${fullNav ? "" : "hide-text"}`}
            >
              {t(item.name)}
            </Typography>
            {item.components.map((component: any, componentIndex: number) => {
              return (
                <React.Fragment key={componentIndex}>
                  <Box
                    key={componentIndex}
                    onMouseOver={() => setItemHover(component.name)}
                    onMouseLeave={() => setItemHover("")}
                    className={
                      "side__menu__item " +
                      ((routeName.includes(component.path) &&
                        !component.children) ||
                      (routeName.includes(component.path) &&
                        component.children &&
                        !fullNav)
                        ? "active"
                        : "")
                    }
                    style={{
                      display:
                        component.roles &&
                        (component.roles.includes(user.role) ||
                          component.roles.includes(user.type))
                          ? "flex"
                          : "none",
                    }}
                    onClick={navigate.bind(null, component)}
                  >
                    <img
                      className="nav-icon"
                      alt={component.name}
                      src={
                        (routeName.includes(component.path) &&
                          !component.children) ||
                        (routeName.includes(component.path) &&
                          component.children &&
                          !fullNav) ||
                        itemHover === component.name
                          ? component.activeIcon
                          : component.icon
                      }
                    />
                    <Box className={`nav-text ${fullNav ? "" : "hide-text"}`}>
                      {t(component.name)}
                      {component.children && (
                        <Box
                          className="expand__subitems"
                          onClick={setOpenSubItems.bind(null, !openSubItems)}
                        >
                          {openSubItems ? <RemoveIcon /> : <AddIcon />}
                        </Box>
                      )}
                      {component.type === "link" && <ChevronRightIcon />}
                    </Box>
                  </Box>
                  {component.children && component.children.length && fullNav && (
                    <Box
                      className={`side__menu__item__children ${
                        openSubItems ? "expand__items" : ""
                      }`}
                    >
                      {component.children.map(
                        (child: any, childIndex: number) => {
                          return (
                            <Box
                              key={childIndex}
                              onMouseOver={() => setItemHover(child.name)}
                              onMouseLeave={() => setItemHover("")}
                              className={
                                "side__menu__item " +
                                (routeName.includes(child.path) ? "active" : "")
                              }
                              style={{
                                display:
                                  child.roles &&
                                  (child.roles.includes(user.role) ||
                                    child.roles.includes(user.type))
                                    ? "flex"
                                    : "none",
                              }}
                              onClick={navigate.bind(null, child)}
                            >
                              <Box
                                className={`nav-text ${
                                  fullNav ? "" : "hide-text"
                                }`}
                              >
                                {t(child.name)}
                              </Box>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
      <Box className="expand">
        {!fullNav && <ChevronRightIcon onClick={openNav} />}
        {fullNav && (
          <Box className="expand-full" onClick={closeNav}>
            <ChevronLeftIcon />
            <Box className="nav-text">{t("collapse")}</Box>
          </Box>
        )}
      </Box>
      <Box className="version">
        <p>V{config.appVersion}</p>
      </Box>
    </Box>
  );
};
