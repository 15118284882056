import "./upload-file.scoped.scss";
import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { UserService } from "../../services/user/user.service";
import { showPopupToastMsg, showToastMsg } from "../../helpers";

export const UploadFileDialog = (props: any) => {
  const [t] = useTranslation();
  const usersServices = new UserService();
  const handleClose = (value: any) => props.onClose(value);
  const inputFileRef = useRef(null);
  const [saving, setSaving] = useState(false);
  const [file, setFile] = useState<any>({});

  const submit = async () => {
    setSaving(true);
    const usersForm = new FormData();
    usersForm.append("file", file, file.name);
    const res = await usersServices.importUsers(usersForm);
    if (res && res.message === "SUCCESS") {
      showToastMsg("success", t("CSV_FILE_WILL_IMPORT"));
    } else {
      const message = res.error || t("SOME_THING_WENT_WRONG");
      showPopupToastMsg("error", message);
    }
    setSaving(false);
    handleClose(true);
  };

  const onFileChange = async (e: any) => {
    const files = e.target.files;
    setFile(files[0]);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const clickUpload = () => {
    if (!saving) {
      const element: any = inputFileRef.current;
      element.click();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName={
        props.dialogClassName ? props.dialogClassName : "modal-md"
      }
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("UPLOAD_IMPORT_USERS_FILE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="content">
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
            style={{ display: "none" }}
            multiple
            accept="text/csv"
          />
          <Box className="text-center">
            <Box className="upload" onClick={clickUpload}>
              <CloudUploadIcon />
            </Box>

            <a href="/sample-import-user-file.csv" download>
              {t("SAMPLE_FILE")}
            </a>
          </Box>
          <Typography
            variant="subtitle1"
            style={{ color: "var(--primary-text)" }}
          >
            {file.name}
          </Typography>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={submit}
          className="btn-primary-theme"
          variant="contained"
          color="primary"
          disabled={saving || !file || !file.name}
        >
          {t("SUBMIT")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
