import React from "react";
import "./user-cases.dialog.scoped.scss";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";

export const UserCasesDilaog = (props: any) => {
  const { user, show, onClose } = props;
  const { projects, name } = user;

  const handleClose = (value: any) => onClose(value);
  const [t] = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("USER_CASES")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table>
          <thead>
            <tr>
              <th>{t("PROJECT")}</th>
              <th>{t("RELEASE")}</th>
              <th>{t("CONTACT")}</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Box
                      className="double-column"
                      onClick={() => window.open(`/case/${item.id}`, "_blank")}
                    >
                      <Typography variant="h6">{item.title}</Typography>
                      <Typography variant="body1" className="hint">
                        {item.internalId}
                      </Typography>
                    </Box>
                  </td>
                  <td>
                    <Typography variant="body1">
                      {item.major.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                      .
                      {item.minor.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                      .
                      {item.version.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{name}</Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
