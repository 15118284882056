import React, { useContext, useState } from "react";
import "./support.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";
import { icons } from "../../statics/icons";
import { useTranslation } from "react-i18next";
import { ContactSupportDialog } from "../../dialogs/contact-support/contact-support.dialog";
import { GlobalContext } from "../../store";

const SupportBlockComponent = () => {
  const [t] = useTranslation();
  const { contactData } = useContext(GlobalContext);

  const [showContactSupportDialog, setShowContactSupportDialog] = useState(
    false
  );

  return (
    <Box className="content">
      <Box className="global__box__content p-0">
        <Box className="box__content">
          <Box className="info">
            <Typography variant="h6">{t("DASHBOARD.QUESTIONS")}</Typography>
            <Typography variant="subtitle2">
              {t("DASHBOARD.QUESTIONS_TEXT")}
            </Typography>
          </Box>
          <img
            src={icons.PAGES.DASHBOARD.COMMUNITY}
            alt=""
            className="m-auto"
          />
        </Box>
        <Box
          className="action"
          onClick={setShowContactSupportDialog.bind(null, true)}
        >
          <Typography variant="body1">
            {t("DASHBOARD.GO_TO_HELP_CENTER")}
          </Typography>
        </Box>
      </Box>
      <Box className="global__box__content p-0">
        <Box className="box__content">
          <Box className="info">
            <Typography variant="h6">
              {t("DASHBOARD.CONTACT_SUPPORT")}
            </Typography>
            <Typography variant="subtitle2">
              {t("DASHBOARD.CONTACT_SUPPORT_TEXT")}
            </Typography>
          </Box>
          <img src={icons.PAGES.DASHBOARD.SUPPORT} alt="" className="m-auto" />
        </Box>
        <Box
          className="action"
          onClick={setShowContactSupportDialog.bind(null, true)}
        >
          <Typography variant="body1">
            {t("DASHBOARD.CONTACT_SUPPORT")}
          </Typography>
        </Box>
      </Box>
      {showContactSupportDialog && (
        <ContactSupportDialog
          data={contactData}
          show={showContactSupportDialog}
          onClose={setShowContactSupportDialog.bind(null, false)}
        />
      )}
    </Box>
  );
};

export default SupportBlockComponent;
