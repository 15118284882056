import { icons } from "../../../statics/icons";

enum TranslationValuesKeys {
  COUNTRIES = "countries",
  STATUS = "status",
  RESOLUTIONS = "resolutions",
  RELATIONSHIPS = "relationships",
  CATEGORIES = "categories",
  AREAS = "areas",
  TECHNIQUES = "techniques",
  MACHINE_LEARNING = "machine_learning",
  ALGORITHMS = "algorithms",
  OPERATIONS = "operations",
}

export interface TranslationValue {
  name: string;
  key: TranslationValuesKeys;
  icon: string;
  activeIcon: string;
  showIconsOption?: boolean;
}

export const translationValues: TranslationValue[] = [
  {
    name: "Countries",
    key: TranslationValuesKeys.COUNTRIES,
    icon: icons.PAGES.SETTINGS.country,
    activeIcon: icons.PAGES.SETTINGS.countryActive,
  },
  {
    name: "Status",
    key: TranslationValuesKeys.STATUS,
    icon: icons.PAGES.SETTINGS.status,
    activeIcon: icons.PAGES.SETTINGS.statusActive,
  },
  {
    name: "Resolutions",
    key: TranslationValuesKeys.RESOLUTIONS,
    icon: icons.PAGES.SETTINGS.resolution,
    activeIcon: icons.PAGES.SETTINGS.resolutionActive,
  },
  {
    name: "Relationships",
    key: TranslationValuesKeys.RELATIONSHIPS,
    icon: icons.PAGES.SETTINGS.relationship,
    activeIcon: icons.PAGES.SETTINGS.relationshipActive,
  },
  {
    name: "RISK_LEVELS",
    key: TranslationValuesKeys.CATEGORIES,
    icon: icons.PAGES.SETTINGS.category,
    activeIcon: icons.PAGES.SETTINGS.categoryActive,
    showIconsOption: true,
  },
  {
    name: "AI_AREAS",
    key: TranslationValuesKeys.AREAS,
    icon: icons.PAGES.SETTINGS.areas,
    activeIcon: icons.PAGES.SETTINGS.areasActive,
    showIconsOption: true,
  },
  {
    name: "AI_TECHNIQUES",
    key: TranslationValuesKeys.TECHNIQUES,
    icon: icons.PAGES.SETTINGS.techniques,
    activeIcon: icons.PAGES.SETTINGS.techniquesActive,
  },
  {
    name: "AI_MACHINE_LEARNING",
    key: TranslationValuesKeys.MACHINE_LEARNING,
    icon: icons.PAGES.SETTINGS.machineLearning,
    activeIcon: icons.PAGES.SETTINGS.machineLearningActive,
  },
  {
    name: "AI_ALGORITHMS",
    key: TranslationValuesKeys.ALGORITHMS,
    icon: icons.PAGES.SETTINGS.algorithm,
    activeIcon: icons.PAGES.SETTINGS.algorithmActive,
    showIconsOption: true,
  },
  {
    name: "AI_OPERATIONS",
    key: TranslationValuesKeys.OPERATIONS,
    icon: icons.PAGES.SETTINGS.operation,
    activeIcon: icons.PAGES.SETTINGS.operationActive,
    showIconsOption: true,
  },
];
