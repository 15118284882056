import "./table-row.component.scoped.scss";
import React, { useState, useEffect } from "react";
import { TableRowProps } from "../../../types/table-row-props";
import { TableColumn } from "../../../types/table-column";
import { history } from "react-router-guard";
import { formatDate, showPopupToastMsg, showToastMsg } from "../../../helpers";
import { icons } from "../../../statics/icons";
import {
  withStyles,
  MenuItem,
  ListItemText,
  Menu,
  Checkbox,
  Box,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { UsersService } from "../../../services/users/users.service";
import { ConfirmDialog } from "../../../dialogs/confirm-dialog/confirm-dialog";
import { LocalStorageService } from "../../../services/local-storage/local-storage";
import { useTranslation } from "react-i18next";
import AddNewUserModal from "../../../dialogs/add-newUser-modal/add-new-user";
import { UserCasesDilaog } from "../../../dialogs/user-cases/user-cases.dilaog";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  // @ts-ignore
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const TableRowComponent = (props: TableRowProps) => {
  const [t] = useTranslation();
  const {
    columns,
    data,
    clickableRow,
    refresh,
    selectedCase,
    tableName,
  } = props;
  const localStorageService: LocalStorageService = new LocalStorageService();
  const usersService: UsersService = new UsersService();

  const [row, setRow]: any = useState(data);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userImageError, setUserImageError] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isReactivateUser, setIsReactivateUser] = useState(false);
  const [showUserCasesModel, setShowUserCasesModel] = useState(false);
  const [userCases, setUserCases] = useState<any>([]);

  const handleDialogClose = (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      if (dialogTitle === "confirm delete") {
        handleDeleteUser();
      } else if (dialogTitle === "confirm deactivate") {
        handleDeactivateUser();
      } else if (dialogTitle === "confirm activate") {
        handleReactivateUser();
      }
    }
    setDialogTitle("");
    setDialogBody("");
  };

  const deleteUser = () => {
    handleClose();
    setDialogTitle("confirm delete");
    setDialogBody("are you sure you want to delete the user");
    setShowConfirmModal(true);
  };

  const handleDeleteUser = async () => {
    const res = await usersService.delete(row.id);
    if (res && res.message === "deleted") {
      refresh("delete");
      showToastMsg("success", t("user deleted successfully"));
    } else if (res && res.message) {
      showPopupToastMsg("error", t(res.message));
    }
  };

  const deactivateUser = () => {
    handleClose();
    setDialogTitle("confirm deactivate");
    setDialogBody("are you sure you want to deactivate the user");
    setShowConfirmModal(true);
  };

  const handleDeactivateUser = async () => {
    const res = await usersService.deactivate(row.id);
    if (res && res.message === "deactivated") {
      refresh();
      showToastMsg("success", t("user deactivated successfully"));
    } else if (res.message) {
      showPopupToastMsg("error", t(res.message));
    } else {
      showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const reactivateUser = () => {
    handleClose();
    setIsReactivateUser(true);
    setShowUserModal(true);
    // setDialogTitle("confirm activate");
    // setDialogBody("are you sure you want to activate the user");
    // setShowConfirmModal(true);
  };

  const handleReactivateUser = async () => {
    const res = await usersService.activate(row.id);
    if (res && res.message === "activated") {
      refresh();
      showToastMsg("success", t("user activated successfully"));
    }
  };

  const inviteUser = async () => {
    handleClose();
    await usersService.renewTokenValidity({
      keycloakUserId: row.keycloakUserId,
      email: row.email,
      name: row.name,
      tenantId: localStorageService.getData("tenantId"),
      link: `${window.location.origin}`,
    });
    showToastMsg("success", t("An email has been sent to the user"));
  };

  const handleModalUserClose = (value: any) => {
    setTimeout(() => {
      setIsReactivateUser(false);
      setShowUserModal(false);
    }, 0);
    if (value) {
      refresh();
    }
  };

  useEffect(() => {
    setRow(props.data);
    // eslint-disable-next-line
  }, [props.data]);

  const navigator = (type: string) => {
    history.push(type);
  };

  const tableNavigator = (event: any) => {
    event.stopPropagation();
    if (
      clickableRow &&
      event.target.id !== "checkbox" &&
      event.target.id !== "actions"
      // &&
      // !event.target.closest("#customized-menu") &&
      // !event.target.closest("#actions")
    ) {
      if (tableName === "cases") {
        const [page, path] = clickableRow;
        const completePath = data[path];
        navigator(`${page}/${completePath}`);
      } else if (tableName === "users") {
        setShowUserModal(true);
      }
    }
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCases = (user: any) => {
    console.log(user);
    if (user.projects.length) {
      setUserCases(user);
      setShowUserCasesModel(true);
    }
  };

  return (
    <tr
      onClick={tableNavigator}
      style={{ cursor: clickableRow ? "pointer" : "initial" }}
    >
      {columns.map((column: TableColumn, index: number) => {
        const { secondaryValue }: any = column;
        return (
          <td
            className={column.type === "checkbox" ? " checkbox-column" : ""}
            style={{
              width: `${
                Number.isInteger(column.width)
                  ? column.width + "%"
                  : "fit-content"
              }`,
            }}
            key={index}
          >
            {column.type === "string" ? (
              <Box className={secondaryValue ? "double-column" : ""}>
                <Typography variant="h6">{row[column.value]}</Typography>
                {secondaryValue && (
                  <Typography variant="body1" className="hint">
                    {row[secondaryValue]}
                  </Typography>
                )}
              </Box>
            ) : column.type === "client" ? (
              <Box
                className={`d-flex align-items-center ${
                  secondaryValue ? " double-column" : ""
                }`}
                style={{ gap: ".5rem" }}
              >
                <Box className="user__profile__pic">
                  {row.profilePicURL && !userImageError ? (
                    <img
                      src={row.profilePicURL}
                      alt=""
                      onError={setUserImageError.bind(null, true)}
                    />
                  ) : (
                    <span className="text-uppercase">
                      {row.firstName[0] + row.lastName[0]}
                    </span>
                  )}
                </Box>
                <Box>
                  <Typography variant="h6">
                    {row.firstName + " " + row.lastName}
                  </Typography>
                  <Typography variant="body1">{row[secondaryValue]}</Typography>
                </Box>
              </Box>
            ) : column.type === "role" ? (
              <Box className={secondaryValue ? "double-column" : ""}>
                <Typography variant="h6">
                  {t(
                    row.type === "superUser" ? "COORDINATOR" : row[column.value]
                  )}
                </Typography>
                {secondaryValue && (
                  <Typography variant="body1" className="hint">
                    {t(row[secondaryValue])}
                  </Typography>
                )}
              </Box>
            ) : column.type === "date" ? (
              <Typography variant="h6">
                {formatDate(row[column.value], "DD.MM.YYYY")}
              </Typography>
            ) : column.type === "time" ? (
              <Typography variant="h6">
                {formatDate(row[column.value], "HH:mm:ss")}
              </Typography>
            ) : column.type === "object" ? (
              <Box className="object">
                <img
                  src={
                    row[column.value].iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                          row[column.value].iconKey
                        ]
                      : icons.categoryPrimary
                  }
                  alt=""
                />
                <Typography variant="h6">{row[column.value].name}</Typography>
              </Box>
            ) : column.type === "arrayIcons" ? (
              <Box className="object" style={{ flexWrap: "wrap" }}>
                {row[column.value].map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {row[column.value].length > 1 ? (
                        <StyledTooltip title={item.name} placement="bottom">
                          <img
                            src={
                              item.iconKey
                                ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                    item.iconKey
                                  ]
                                : icons.categoryPrimary
                            }
                            alt=""
                          />
                        </StyledTooltip>
                      ) : (
                        <Box className="object">
                          <img
                            src={
                              item.iconKey
                                ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                    item.iconKey
                                  ]
                                : icons.categoryPrimary
                            }
                            alt=""
                          />
                          <Typography variant="h6">{item.name}</Typography>
                        </Box>
                      )}
                    </React.Fragment>
                  );
                })}
              </Box>
            ) : column.type === "release" ? (
              <Typography variant="h6">
                {row.major.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
                .
                {row.minor.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
                .
                {row.version.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
              </Typography>
            ) : column.type === "logID" ? (
              <Typography variant="h6">{row.value[column.value]}</Typography>
            ) : column.type === "logKey" ? (
              <Typography variant="h6">
                {t(row[column.value], { obj: row.value })}
              </Typography>
            ) : column.type === "status" ? (
              <Box className={"status-col " + row[column.value]}>
                {t(row[column.value])}
              </Box>
            ) : column.type === "task" ? (
              <Box>
                {row.tasks === 0
                  ? t("NEW")
                  : `${row.completedTasks}/${row.tasks}`}
              </Box>
            ) : column.type === "questions_status" ? (
              <Box className="questions-status">
                {row.answeredQuestions === 0 ? (
                  <Box className="new">
                    <img src={icons.PAGES.CASES.PROCCESS.NEW} alt="" />
                    <Typography
                      variant="h6"
                      style={{ color: "var(--secondary-text)" }}
                    >
                      {t("NEW")}
                    </Typography>
                  </Box>
                ) : row.totalQuestions === row.answeredQuestions ? (
                  <Box className="done">
                    <img src={icons.PAGES.CASES.PROCCESS.DONE} alt="" />
                    <Typography
                      variant="h6"
                      style={{ color: "var(--success)" }}
                    >
                      {t("PUBLISHED")}
                    </Typography>
                  </Box>
                ) : (
                  <Box className="ongoing">
                    <img src={icons.PAGES.CASES.PROCCESS.ONGOING} alt="" />
                    <Typography
                      variant="h6"
                      style={{ color: "var(--primary-color)" }}
                    >
                      {`${row.answeredQuestions} ${t("OF")} ${
                        row.totalQuestions
                      }`}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : column.type === "projects" ? (
              <Box
                className={row[column.value].length && "clickable"}
                onClick={() => openCases(row)}
              >
                <Typography variant="h6">
                  {row[column.value].length
                    ? row[column.value].length +
                      " " +
                      (row[column.value].length > 1
                        ? t("PROJECTS")
                        : t("PROJECT"))
                    : t("NO_PROJECTS")}
                </Typography>
              </Box>
            ) : column.type === "checkbox" ? (
              <Box className="checkbox-global" id="checkbox">
                <Checkbox
                  id="checkbox"
                  checked={row.checked}
                  onChange={() => selectedCase(!row.checked, row)}
                  name="checkbox"
                  size="small"
                />
              </Box>
            ) : column.type === "actions" ? (
              <Box id="actions" className="buttons">
                {column.value === "users" && (
                  <Box>
                    <Box
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ padding: "5px", cursor: "pointer" }}
                    >
                      <MoreVertIcon className="menu-btn" />
                    </Box>
                    <StyledMenu
                      // @ts-ignore
                      id="customized-menu"
                      anchorEl={anchorEl}
                      // keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {(row.status === "invited" ||
                        row.status === "deactivated") && (
                        <MenuItem onClick={deleteUser}>
                          <ListItemText primary={t("delete")} />
                        </MenuItem>
                      )}
                      {row.status === "verified" && (
                        <MenuItem onClick={deactivateUser}>
                          <ListItemText primary={t("deactivate")} />
                        </MenuItem>
                      )}
                      {row.status === "deactivated" && (
                        <MenuItem onClick={reactivateUser}>
                          <ListItemText primary={t("reactivate")} />
                        </MenuItem>
                      )}
                      {row.status === "invited" && (
                        <MenuItem onClick={inviteUser}>
                          <ListItemText primary={t("invite again")} />
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setShowUserModal(true);
                        }}
                      >
                        <ListItemText primary={t("EDIT_ROLE")} />
                      </MenuItem>
                    </StyledMenu>
                  </Box>
                )}
              </Box>
            ) : (
              <Box className={column.type}>{row[column.value]}</Box>
            )}
          </td>
        );
      })}
      {showUserModal && (
        <AddNewUserModal
          show={showUserModal}
          user={row}
          editable={true}
          reactivate={isReactivateUser}
          onClose={(value: any) => handleModalUserClose(value)}
        />
      )}
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onClose={handleDialogClose}
          dialogTitle={t(dialogTitle)}
          dialogBody={t(dialogBody)}
          confirmBtnText={t("SUBMIT")}
          cancelBtn={true}
        />
      )}
      {showUserCasesModel && (
        <UserCasesDilaog
          show={showUserCasesModel}
          onClose={() => {
            setShowUserCasesModel(false);
            setUserCases([]);
          }}
          user={userCases}
        />
      )}
    </tr>
  );
};

// : column.type === "dueDate" ? (
//   <Box className="date">
//     {row.isDueDate && <img src="icons/alert.svg" alt="" />}
//     <Box>{formatDate(row[column.value], "DD.MM.YYYY")}</Box>
//   </Box>
// )
