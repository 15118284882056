import React, { useEffect, useContext, useState } from "react";
import "./dashboard-content.component.scoped.scss";
// import { DashboardService } from "../../services/dashboard/dashboard.service";
import DashboardFirstBlock from "../dashboard-first-block/dashboard-first-block.component";
import DashboardSecondBlock from "../dashboard-second-block/dashboard-second-block.component";
import DashboardThirdBlock from "../dashboard-third-block/dashboard-third-block.component";
import { GlobalContext } from "../../../../store";
import { Box } from "@material-ui/core";
import PagesHeadComponent from "../../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../../statics/icons";
import { useTranslation } from "react-i18next";
import { ContentLayout } from "../../../../layouts/content/content.layout";
import { DashboardService } from "../../../../services/dashboard/dashboard.service";
import { MapperService } from "../../../../services/mapper/mapper.service";
import moment from "moment";
import { DashboardCountCards } from "../../../../statics/countCards";
import SupportBlockComponent from "../../../../components/support/support.component";

export default function DashboardContent() {
  const [t] = useTranslation();
  const dashboardService = new DashboardService();
  const mapperService = new MapperService();
  const { user } = useContext(GlobalContext);
  const [counts, setCounts] = useState<any>([]);
  const [cases, setCases] = useState<any>({});
  const [AITeam, setAITeam] = useState<any>({});
  const [categoriesChart, setCategoriesChart] = useState();
  const [statusChart, setStatusChart] = useState();

  const fetchData = async () => {
    const dashboardData = await dashboardService.getDashboardData();
    const mappedCounts = mapperService.mapCounts(
      dashboardData.counts,
      DashboardCountCards
    );
    const mappedCases = mapperService.mapTableCards(
      dashboardData.cases,
      "PROJECTS"
    );

    const mappedUsers = mapperService.mapTableCards(
      dashboardData.users,
      "TEAM"
    );

    setAITeam(mappedUsers);
    setCounts(mappedCounts);
    setCases(mappedCases);
  };

  const fetchCharts = async () => {
    const durationDate = {
      from: moment().subtract(12, "months").format("YYYY-MM-DD"),
      to: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    const categoriesChartData = await dashboardService.getCategoriesChartData(
      durationDate
    );
    setCategoriesChart(categoriesChartData);

    const statusChartData = await dashboardService.getStatusChartData(
      durationDate
    );
    setStatusChart(statusChartData);
  };

  useEffect(() => {
    fetchData();
    fetchCharts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <ContentLayout>
        <PagesHeadComponent
          title={t("PAGES_HEAD.DASHBOARD")}
          subTitle={t("PAGES_HEAD.DASHBOARD_SUB_TITLE")}
          logo={icons.PAGES.HEAD.AI_RISK_LANDSCAPE}
        />
        <Box className="dashboard">
          <Box className="dashboard-content">
            <DashboardFirstBlock counts={counts} />
            <DashboardSecondBlock statusChart={statusChart} cases={cases} />
            <DashboardThirdBlock
              user={user}
              categoriesChart={categoriesChart}
              latestCases={AITeam}
            />
            <SupportBlockComponent />
          </Box>
        </Box>
      </ContentLayout>
    </Box>
  );
}
