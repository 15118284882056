import React from "react";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../../components/header/header.component";
import CreateWorkspaceContent from "../../../components/create-workspace-content/create-workspace-content.component";

const CreateWorkspaceOldPage = () => {
  const commonService = new CommonService();
  const [t] = useTranslation();
  commonService.setTitle(t("create workspace"));

  return (
    <>
      <HeaderComponent />
      <CreateWorkspaceContent />
    </>
  );
};

export default CreateWorkspaceOldPage;
