import React, { useContext, useState } from "react";
import "./case-header.component.scoped.scss";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Box, Typography } from "@material-ui/core";
import { AIRolesByKey } from "../../../types/AI-roles";
import { Dropdown } from "react-bootstrap";
import { icons } from "../../../../../statics/icons";
import { CaseContext, GlobalContext } from "../../../../../store";
import { AIArea } from "../../../types/AI-areas";
import EditNoticeInfo from "../../../../../dialogs/edit-noticeInfo/edit-noticeInfo";
import { history } from "react-router-guard";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../../helpers";
import { ChangeKeyContactDialog } from "../../../dialogs/change-keycontact/change-keycontact.component";

const CaseHeaderComponent = () => {
  const [t] = useTranslation();
  const { caseDetails, areas, loading } = useContext(CaseContext);
  const { user } = useContext(GlobalContext);
  const [showNoticeInfoModal, setShowNoticeInfoModal] = useState(false);
  const [userImageError, setUserImageError] = useState(false);
  const [isUserHover, setIsUserHover] = useState(false);
  const [showChangeKeyContactDialog, setShowChangeKeyContactDialog] = useState(
    false
  );

  const back = () => {
    if (caseDetails.role === "DEVELOPER") {
      history.push("/ai-products");
    } else if (caseDetails.role === "USER") {
      history.push("/ai-demand");
    } else if (
      caseDetails.role === "DISTRIBUTER" ||
      caseDetails.role === "IMPORTER"
    ) {
      history.push("/ai-inventory");
    } else {
      history.push("/dashboard");
    }
  };

  return (
    <Box className="content">
      <Box className="top-content">
        {loading ? (
          <Skeleton variant="text" height={25} width={500} />
        ) : (
          <Box className="title">
            <ArrowBackIcon style={{ cursor: "pointer" }} onClick={back} />
            <Box className="info">
              <Typography
                variant="h6"
                className="text-uppercase"
                style={{ lineHeight: 1 }}
              >
                {t("AI_PRODUCTS")} {">"} {caseDetails.title}
                {"_"}
                {caseDetails.internalId}{" "}
                <small className="text-lowercase" style={{ fontSize: "9px" }}>
                  modified
                </small>
              </Typography>
              <Typography style={{ fontSize: "9px" }}>
                {t("LAST_MODIFIED")},
                {formatDate(caseDetails.updated_at, "DD.MM.YYYY HH:MM")}
              </Typography>
            </Box>
          </Box>
        )}
        <Box className="right">
          {loading ? (
            <Skeleton variant="text" height={25} width={300} />
          ) : (
            <Typography className="text-uppercase" variant="body1">
              {AIRolesByKey[caseDetails.role].name} Version
            </Typography>
          )}
          {loading ? (
            <Skeleton variant="circle" height={35} width={35} />
          ) : (
            <img
              src={AIRolesByKey[caseDetails.role].icon}
              alt={AIRolesByKey[caseDetails.role].title}
              title={AIRolesByKey[caseDetails.role].title}
            />
          )}

          {loading ? (
            <Skeleton variant="rect" height={35} width={10} />
          ) : (
            <Dropdown>
              <Dropdown.Toggle variant="disable" className="p-0">
                <MoreVertIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowNoticeInfoModal(true)}>
                  {t("EDIT_INFORMATION")}
                </Dropdown.Item>
                <Dropdown.Item disabled>{t("EDIT")}</Dropdown.Item>
                <Dropdown.Item disabled>{t("PRINT")}</Dropdown.Item>
                <Dropdown.Item disabled>{t("DOWNLOAD")}</Dropdown.Item>
                <Dropdown.Item disabled>{t("DELETE")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Box>
      </Box>
      <Box className="mt-3 text-right mb-2">
        {loading ? (
          <Skeleton
            className="ml-auto"
            variant="text"
            height={25}
            width={200}
          />
        ) : (
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: 400 }}
          >
            {t("RELEASE")}{" "}
            {caseDetails.major.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
            .
            {caseDetails.minor.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
            .
            {caseDetails.version.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </Typography>
        )}
      </Box>
      <Box className="bottom-content">
        <Box className="info">
          {loading ? (
            <Skeleton variant="text" height={25} width="100%" />
          ) : (
            <Typography
              className="text-capitalize"
              color="primary"
              variant="h1"
            >
              {caseDetails.title}{" "}
              <small className="version">{caseDetails.internalId}</small>
            </Typography>
          )}
          <Typography
            variant="body1"
            className="mt-3"
            style={{
              color: "var(--grey-text)",
              minHeight: 60,
              wordBreak: "break-word",
            }}
          >
            {loading ? (
              <Skeleton variant="text" height={50} width="80%" />
            ) : (
              <>{caseDetails.brief}</>
            )}
          </Typography>
        </Box>
        <Box className="areas">
          {caseDetails.areasIds.map((id: number, index: number) => (
            <Box key={index}>
              {loading ? (
                <Skeleton variant="text" height={25} width={200} />
              ) : (
                <Box className="item">
                  {areas.map((area: AIArea, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        {area.id === id && (
                          <>
                            <img
                              src={
                                area.iconKey
                                  ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                      `${area.iconKey}_WHITE`
                                    ]
                                  : icons.ideaWhite
                              }
                              height={24}
                              alt=""
                            />
                            <Typography variant="body1">{area.name}</Typography>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box className="ml-auto d-flex w-100" style={{ gap: "1rem" }}>
          <Box className="box-content global__box__content  global__box__content_bg category text-center ">
            {loading ? (
              <Skeleton variant="text" width={120} height={15} />
            ) : (
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 500, color: "var(--primary-color)" }}
              >
                {t("RISK_LEVEL")}
              </Typography>
            )}

            {loading ? (
              <Skeleton variant="circle" width={40} height={40} />
            ) : (
              <img
                width={30}
                src={
                  caseDetails.category.iconKey
                    ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                        caseDetails.category.iconKey
                      ]
                    : icons.categoryPrimary
                }
                alt=""
              />
            )}
            {loading ? (
              <Skeleton variant="text" width={150} height={20} />
            ) : (
              <Typography
                variant="body1"
                style={{ color: "var(--grey-text)", fontWeight: 400 }}
              >
                {caseDetails.category.name}
              </Typography>
            )}
          </Box>
          <Box
            className="box-content global__box__content global__box__content_bg"
            onMouseOver={() => setIsUserHover(true)}
            onMouseLeave={() => setIsUserHover(false)}
          >
            {loading ? (
              <Skeleton variant="text" width={150} height={15} />
            ) : (
              <Box className="d-flex align-items-center  justify-content-between">
                <Typography
                  variant="subtitle1"
                  style={{
                    margin: "auto",
                    fontWeight: 500,
                    color: "var(--primary-color)",
                  }}
                >
                  {t("KEY_CONTACT_USER")}
                </Typography>
                {!loading && isUserHover && (
                  <img
                    onClick={() => setShowChangeKeyContactDialog(true)}
                    className="edit-icon"
                    src={icons.edit}
                    alt=""
                  />
                )}
              </Box>
            )}
            {caseDetails.keyContactUser && (
              <Box className="ml-auto key-user">
                {loading ? (
                  <Skeleton variant="circle" width={40} height={40} />
                ) : (
                  <Box className="user__profile__pic">
                    {caseDetails.keyContactUser.profilePicURL &&
                    !userImageError ? (
                      <img
                        src={caseDetails.keyContactUser.profilePicURL}
                        title={
                          caseDetails.keyContactUser.firstName +
                          " " +
                          caseDetails.keyContactUser.lastName
                        }
                        alt=""
                        onError={setUserImageError.bind(null, true)}
                      />
                    ) : (
                      <span className="text-uppercase">
                        {caseDetails.keyContactUser.firstName
                          ? caseDetails.keyContactUser.firstName[0] +
                            caseDetails.keyContactUser.lastName[0]
                          : ""}
                      </span>
                    )}
                  </Box>
                )}
                <Box className="info">
                  {loading ? (
                    <Skeleton variant="text" height={15} width={100} />
                  ) : (
                    <p>
                      {caseDetails.keyContactUser.firstName +
                        " " +
                        caseDetails.keyContactUser.lastName}
                    </p>
                  )}
                  {loading ? (
                    <Skeleton variant="text" height={15} width={130} />
                  ) : (
                    <a href={`mailto:${caseDetails.keyContactUser.email}`}>
                      {caseDetails.keyContactUser.email}
                    </a>
                  )}
                  {loading && user.type !== "superAdmin" ? (
                    <Skeleton variant="text" height={15} width={70} />
                  ) : (
                    <span>
                      {t(caseDetails.keyContactUser.role)} /{" "}
                      {caseDetails.keyContactUser.group}
                    </span>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {showNoticeInfoModal && (
        <EditNoticeInfo
          show={showNoticeInfoModal}
          onClose={(e: any) => setShowNoticeInfoModal(false)}
        />
      )}

      {showChangeKeyContactDialog && (
        <ChangeKeyContactDialog
          show={showChangeKeyContactDialog}
          onClose={() => setShowChangeKeyContactDialog(false)}
        />
      )}
    </Box>
  );
};

export default CaseHeaderComponent;
