import React, { useContext } from "react";
import "./settings-tabs.component.scoped.scss";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-guard";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../store";

const SettingsTabsComponent = () => {
  const [t] = useTranslation();
  const { user } = useContext(GlobalContext);

  return (
    <Box className="settings__tabs">
      <ul>
        <li>
          <NavLink activeClassName="active" exact to="/settings/profile">
            {t("SIDE_MENU.SETTINGS_LIST.PROFILE")}
          </NavLink>
        </li>
        {(user.type === "superAdmin" || user.role === "coordinator") && (
          <li>
            <NavLink
              activeClassName="active"
              exact
              to="/settings/workspace-management"
            >
              {t("SIDE_MENU.SETTINGS_LIST.WORKSPACE")}
            </NavLink>
          </li>
        )}
        {(user.role === "coordinator" || user.type === "superAdmin") && (
          <li>
            <NavLink
              activeClassName="active"
              exact
              to="/settings/role-management"
            >
              {t("SIDE_MENU.SETTINGS_LIST.ROLE_MANAGEMENT")}
            </NavLink>
          </li>
        )}
        {(user.role === "coordinator" || user.type === "superAdmin") && (
          <li>
            <NavLink
              activeClassName="active"
              exact
              to="/settings/workflow-management"
            >
              {t("SIDE_MENU.SETTINGS_LIST.WORKFLOW_MANAGEMENT")}
            </NavLink>
          </li>
        )}
        {(user.role === "coordinator" || user.type === "superAdmin") && (
          <li>
            <NavLink
              activeClassName="active"
              exact
              to="/settings/database-value-management"
            >
              {t("SIDE_MENU.SETTINGS_LIST.DATABASE_VALUE_MANAGEMENT")}
            </NavLink>
          </li>
        )}
        {(user.role === "coordinator" || user.type === "superAdmin") && (
          <li>
            <NavLink
              activeClassName="active"
              exact
              to="/settings/branch-management"
            >
              {t("SIDE_MENU.SETTINGS_LIST.BRANCH_MANAGEMENT")}
            </NavLink>
          </li>
        )}
        {(user.role === "coordinator" || user.type === "superAdmin") && (
          <li>
            <NavLink activeClassName="active" exact to="/settings/activity-log">
              {t("SIDE_MENU.SETTINGS_LIST.ACTIVITY_LOG")}
            </NavLink>
          </li>
        )}
      </ul>
    </Box>
  );
};

export default SettingsTabsComponent;
