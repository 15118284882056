import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CommonService } from "../../../services/common/common.service";
import { GlobalContext, LogsProvider } from "../../../store";
import { LogfileContent } from "../../../modules/settings/components/logfile/logfile-content/logfile.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { Box } from "@material-ui/core";

export default function LogfilePage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.ACTIVITY_LOG"));
      setPageTitle(t("PAGE_META_TITLES.ACTIVITY_LOG"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LogsProvider>
      <Box className="role-contain">
        <Box className="content">
          <SettingsHead
            title={t("logfile")}
            subTitle={t("logfile description")}
          />
          <LogfileContent />
        </Box>
      </Box>
    </LogsProvider>
  );
}
