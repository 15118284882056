import React, { useEffect, useState } from "react";
import { icons } from "../../../../statics/icons";
import "./tasks-row.component.scoped.scss";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { showPopupToastMsg, showToastMsg } from "../../../../helpers";
import { Box, Button, Typography } from "@material-ui/core";
import { TasksService } from "../../../../services/tasks/tasks.service";
import TaskComment from "../task-comments/task-comment/task-comment.component";

const TasksRowComponent = (props: any) => {
  const { refresh, data, user } = props;
  const [t] = useTranslation();
  const tasksService = new TasksService();
  const [userImageError, setUserImageError] = useState(false);
  const [comments, setComments] = useState(data.comments);
  const [openComments, setOpenComments] = useState(false);

  const [responsibleImageError, setResponsibleImageError] = useState(false);
  const [finishedUserImageError, setFinishedUserImageError] = useState(false);
  const [task, setTask] = useState(data);
  const dueDays = moment(data.deadline).diff(moment(new Date()), "days");

  const completeTask = async () => {
    const response = await tasksService.update(task.id, { status: true });
    if (response === "Success") {
      showToastMsg("success", t("task completed successfully"));
    } else {
      showPopupToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
    refresh();
  };

  const handleUpdateComments = (comment: string) => {
    const commentsCopy = [...comments];
    commentsCopy.push(comment);
    setComments(commentsCopy);
  };

  const handleDeleteComment = (index: number) => {
    const commentsCopy = [...comments];
    commentsCopy.splice(index, 1);
    // empty the comments first for rerender again.
    setComments([]);
    setComments(commentsCopy);
  };

  useEffect(() => {
    setTask(props.data);
  }, [props.data]);

  return (
    <>
      <Box className="global__box__content content mb-4">
        <Box
          className="left__section d-flex align-items-start"
          style={{ gap: "1.5rem" }}
        >
          <Box className="icon-wrap mt-1">
            <img
              src={
                task.status === 0 ? icons.PAGES.TASKS.TASK : icons.CIRCLE_CHECK
              }
              alt="TASK"
            />
          </Box>
          <Box className="task__details w-100">
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 400, lineHeight: "20px" }}
            >
              {task.question}
            </Typography>
            <Typography
              variant="subtitle2"
              className="mt-2"
              style={{ lineHeight: "22px" }}
            >
              {task.task}
            </Typography>
            <Box
              className="d-flex align-items-center mt-4"
              style={{ gap: "1rem" }}
            >
              <Typography
                variant="body1"
                className="d-flex align-items-center"
                style={{ gap: ".3rem" }}
              >
                <img src={icons.AI_SOFTWARE} alt="AI-Software" />
                {task.caseTitle}
              </Typography>
              <Box className="user__info" style={{ gap: ".4rem" }}>
                <Box className="user__profile__pic">
                  {task.user.profilePicURL && !userImageError ? (
                    <img
                      src={task.user.profilePicURL || icons.PLACEHOLDER.user}
                      title={task.user.firstName + " " + task.user.lastName}
                      alt=""
                      onError={setUserImageError.bind(null, true)}
                    />
                  ) : (
                    <span className="text-uppercase">
                      {task.user.firstName
                        ? task.user.firstName[0] + task.user.lastName[0]
                        : ""}
                    </span>
                  )}
                </Box>
                <Typography variant="body1">
                  {task.user
                    ? task.user.firstName + " " + task.user.lastName
                    : "Anonymous"}
                </Typography>
              </Box>
              {task.status === 0 ? (
                <>
                  {comments.length === 0 ? (
                    <Button
                      className="btn-primary-theme"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={setOpenComments.bind(null, !openComments)}
                    >
                      <img
                        src={icons.PLUS_CIRCLE}
                        alt="add comment"
                        width={20}
                        className="mr-2"
                      />
                      {t("ADD_COMMENT")}
                    </Button>
                  ) : (
                    <Typography
                      variant="body1"
                      className="d-flex align-items-center"
                      onClick={setOpenComments.bind(null, !openComments)}
                      style={{
                        cursor: "pointer",
                        gap: ".3rem",
                        color: openComments ? "var(--accent-color)" : "",
                      }}
                    >
                      <img
                        src={
                          openComments ? icons.COMMENTS_ACTIVE : icons.COMMENTS
                        }
                        alt="Task Comments"
                      />
                      {`${comments.length} ${t("COMMENTS")}`}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {comments.length !== 0 ? (
                    <Typography
                      variant="body1"
                      className="d-flex align-items-center"
                      onClick={setOpenComments.bind(null, !openComments)}
                      style={{
                        cursor: "pointer",
                        gap: ".3rem",
                        color: openComments ? "var(--accent-color)" : "",
                      }}
                    >
                      <img
                        src={
                          openComments ? icons.COMMENTS_ACTIVE : icons.COMMENTS
                        }
                        alt="Task Comments"
                      />
                      {`${comments.length} ${t("COMMENTS")}`}
                    </Typography>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Box className="right__section">
          <Box className="item">
            <Box className="user__info" style={{ gap: ".4rem" }}>
              <Box className="user__profile__pic">
                {task.responsible.profilePicURL && !responsibleImageError ? (
                  <img
                    src={
                      task.responsible.profilePicURL || icons.PLACEHOLDER.user
                    }
                    title={
                      task.responsible.firstName +
                      " " +
                      task.responsible.lastName
                    }
                    alt=""
                    onError={setResponsibleImageError.bind(null, true)}
                  />
                ) : (
                  <span className="text-uppercase">
                    {task.responsible.firstName
                      ? task.responsible.firstName[0] +
                        task.responsible.lastName[0]
                      : ""}
                  </span>
                )}
              </Box>
              <Typography variant="body1">
                {task.responsible
                  ? task.responsible.firstName + " " + task.responsible.lastName
                  : "Anonymous"}
              </Typography>
            </Box>
            <Typography variant="body2">{t("RESPONSIBLE")}</Typography>
          </Box>

          {task.status === 1 && task.finishedBy && (
            <>
              <Box className="item">
                <Box className="user__info" style={{ gap: ".4rem" }}>
                  <Box className="user__profile__pic">
                    {task.user.profilePicURL && !finishedUserImageError ? (
                      <img
                        src={task.user.profilePicURL || icons.PLACEHOLDER.user}
                        title={task.user.firstName + " " + task.user.lastName}
                        alt=""
                        onError={setFinishedUserImageError.bind(null, true)}
                      />
                    ) : (
                      <span className="text-uppercase">
                        {task.user.firstName
                          ? task.user.firstName[0] + task.user.lastName[0]
                          : ""}
                      </span>
                    )}
                  </Box>
                  <Typography variant="body1">
                    {task.finishedBy.firstName + " " + task.finishedBy.lastName}
                  </Typography>
                </Box>
                <Typography variant="body2">{t("COMPLETED_BY")}</Typography>
              </Box>

              <Typography
                variant="subtitle2"
                className="d-flex align-items-center ml-auto"
              >
                <img src={icons.CALENDER} alt="Calender" className="mr-1" />
                {moment(task.deadline).format("DD.MM.YYYY")}
              </Typography>
            </>
          )}

          {task.status === 0 && (
            <>
              <Box>
                <Typography
                  variant="subtitle2"
                  className="d-flex align-items-center"
                >
                  <img src={icons.CALENDER} alt="Calender" className="mr-1" />
                  {moment(task.deadline).format("DD.MM.YYYY")}
                </Typography>

                {dueDays <= 5 && (
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 400,
                      color: dueDays >= 0 ? "#212529" : "#CA310B",
                      display: "flex",
                      alignItems: "start",
                      gap: "0.5rem",
                    }}
                  >
                    <img src={icons.warning} alt="Warning" width={16} />
                    <Trans
                      i18nKey={
                        dueDays >= 0 ? "DUE_IN_DAYS" : "OVER_DUE_SINCE_DAYS"
                      }
                      values={{ days: dueDays > 0 ? dueDays : -1 * dueDays }}
                    ></Trans>
                  </Typography>
                )}
              </Box>

              <Button
                className="btn-primary-theme ml-auto"
                variant="contained"
                size="small"
                color="primary"
                onClick={completeTask}
              >
                <img
                  src={icons.checkWhite}
                  alt=""
                  width={20}
                  className="icon mr-2"
                />
                {t("COMPLETE_TASK")}
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* Start Comment Section */}
      <Box
        className={`toggle  ${
          openComments && (comments.length || task.status === 0) ? "show" : ""
        }`}
      >
        {openComments && (comments.length || task.status === 0) ? (
          <TaskComment
            internalId={task.internalId}
            status={task.status}
            comments={comments}
            user={user}
            taskId={task.id}
            onDeleteComment={(index: number) => handleDeleteComment(index)}
            onUpdateComments={(comment: any) => handleUpdateComments(comment)}
          />
        ) : null}
      </Box>
      {/* End Comment Section */}
    </>
  );
};

export default TasksRowComponent;
